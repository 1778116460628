<template>
  <div class="main">
    <div class="transaction-list">
      <div class="row">
        <div class="container">
          <h1 class="title">Reports</h1>
          <div class="form-group">
            <label for="partyname">Party *</label>
            <v-select :clearable="false" placeholder="Select Party" id="partyname" v-model="formData.partyname"
              :options="party" label="short_name" :reduce="party => party.autoid" selectOnTab="True" />

          </div>
          <div class="form-group">
            <label for="vesselName">Vessel *</label>
            <v-select :clearable="false" id="vesselName" placeholder="Select Vessel" v-model="formData.vesselName"
              :options="vessel" label="Name" :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="voyageName">Voyage *</label>
            <v-select :clearable="false" id="voyageName" v-model="formData.voyageName" :options="voyage" label="Name"
              :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage" selectOnTab="True" />
          </div>
          <div class="form-group">
            <label for="locationname">Location *</label>
            <v-select :clearable="false" v-model="formData.locationname" :options="location" label="Name"
              :reduce="location => location.locationid" placeholder="Select Location" selectOnTab="True"
              append-to-body />
          </div>
          <div class="form-group">
            <label for="movementname">Movement Type *</label>
            <v-select :clearable="false" v-model="formData.movementname" :options="movement" label="Name"
              :reduce="movement => movement.Movid" placeholder="Select Movement" selectOnTab="True" append-to-body />
          </div>
          <div class="form-group">
            <label for="fromdate">From Date *</label>
            <input type="text" id="fromdate" v-model="formData.fromdate" placeholder="dd/mm/yyyy"
              v-on:blur="checkIsDate()" />
          </div>
          <div class="form-group">
            <label for="todate">To Date *</label>
            <input type="text" id="todate" v-model="formData.todate" placeholder="dd/mm/yyyy"
              v-on:blur="checkIsToDate()" />
          </div>
          <div class="form-group">
            <label for="contnumber">Container No</label>
            <input type="text" id="contnumber" v-model="formData.contnumber"
              placeholder="Generating Report for Specific Container(s)..." />
          </div>
          <div class="form-group">
            <button type="button" class="excel-button" @click="getReportDetails">Show Result</button>
            <button type="button" class="cancel-button1">Cancel</button>
          </div>
        </div>
      </div>
      <div class="controls">
        <div class="search-container">
          <input type="text" v-model="searchQuery" placeholder="Search..." />
          <span class="search-icon">&#128269;</span>
        </div>
      </div>
      <div class="table-container">
        <table class="port-table">
          <thead>
            <tr>
              <th>Report No.</th>
              <th>Report Date</th>
              <th>Bill No.</th>
              <th>Applicant</th>
              <th>Account</th>
              <th>Print</th>
              <th colspan="2">Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="report in paginatedReports" :key="report.rptno">
              <td>{{ report.rptno || 'N/A' }}</td>

              <td>{{ formatDate(report.rptdate) }}</td>
              <td>{{ report.billno }}</td>
              <td>{{ report.applicantname }}</td>
              <td>{{ report.accountname }}</td>
              <td>
                <button @click="openPDF(report.filename)" class="pdf-button">
                  <i class="fas fa-file-pdf"></i> View PDF
                </button>
              </td>
              <td>
                <button @click="openEditModal(report.rptno)" class="edit-button">
                  <i class="fas fa-edit"></i> Edit
                </button>
              </td>
              <td>
                <button @click="openAddModal(report.rptno)" class="edit-button">
                  <i class="fas fa-plus"></i> Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bottom-controls">
        <div class="rows-per-table">
          <button v-for="count in [10, 20, 50]" :key="count"
            :class="{ 'rows-per-table-button': true, 'active': rowsPerPage === count }"
            @click="changeRowsPerPage(count)">
            {{ count }}
          </button>
        </div>
        <div class="pagination">
          <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">&laquo;</button>
          <button v-for="page in totalPages" :key="page" :class="{ 'active': page === currentPage }"
            @click="changePage(page)">
            {{ page }}
          </button>
          <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">&raquo;</button>
        </div>
      </div>
      <div v-if="isModalVisible" class="edit-overlay">
        <div class="edit-overlay-content">
          <span class="close-btn" @click="closeModal">&times;</span>
          <h2>Container Inspection Report</h2>
          <div class="table-container">
            <table class="port-table">
              <thead>
                <tr>
                  <th>Conatiner no</th>
                  <th>ISO</th>
                  <th>Movement</th>
                  <th>Place</th>
                  <th>Date</th>
                  <th>Applicant</th>
                  <th>Account</th>
                  <th>Vessel</th>
                  <th>Voyage</th>
                  <th>Clear</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="transaction in transactions" :key="transaction.reportid">
                  <td>{{ transaction.contnumber }}</td>
                  <td>{{ transaction.iso_code }}</td>
                  <td>{{ transaction.MovementName }}</td>
                  <td>{{ transaction.LocationName }}</td>
                  <td>{{ formatDate(transaction.inspdate) }}</td>
                  <td>{{ transaction.applicantname }}</td>
                  <td>{{ transaction.accountname }}</td>
                  <td>{{ transaction.VesselName }}</td>
                  <td>{{ transaction.VoyageName }}</td>
                  <td><button @click="ClearReportFromInspection(transaction.reportid, transaction.rptno)"
                      class="edit-button">
                      <i class="fas fa-edit"></i> Clear
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Save and Cancel buttons -->
            <div class="form-buttons">
              <button type="button" class="cancel-button" @click="closeModal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAddModalVisible" class="edit-overlay">
        <div class="edit-overlay-content">
          <span class="close-btn" @click="closeModal">&times;</span>
          <h1 class="title">Inspection Report</h1>
          <div class="row">
            <div class="container">
              <div class="form-group">
                <label for="partyname">Party *</label>
                <v-select :clearable="false" placeholder="Select Party" id="partyname" v-model="formData.partyname"
                  :options="party" label="short_name" :reduce="party => party.autoid" selectOnTab="True" />

              </div>
              <div class="form-group">
                <label for="vesselName">Vessel *</label>
                <v-select :clearable="false" id="vesselName" placeholder="Select Vessel" v-model="formData.vesselName"
                  :options="vessel" label="Name" :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
              </div>
              <div class="form-group">
                <label for="voyageName">Voyage *</label>
                <v-select :clearable="false" id="voyageName" v-model="formData.voyageName" :options="voyage"
                  label="Name" :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage" selectOnTab="True" />
              </div>
              <div class="form-group">
                <label for="locationname">Location *</label>
                <v-select :clearable="false" v-model="formData.locationname" :options="location" label="Name"
                  :reduce="location => location.locationid" placeholder="Select Location" selectOnTab="True"
                  append-to-body />
              </div>
              <div class="form-group">
                <label for="movementname">Movement Type *</label>
                <v-select :clearable="false" v-model="formData.movementname" :options="movement" label="Name"
                  :reduce="movement => movement.Movid" placeholder="Select Movement" selectOnTab="True"
                  append-to-body />
              </div>
              <div class="form-group">
                <label for="fromdate">From Date *</label>
                <input type="date" id="fromdate" v-model="formData.fromdate"
                  :max="new Date().toLocaleDateString('en-ca')" />
              </div>
              <div class="form-group">
                <label for="todate">To Date *</label>
                <input type="date" id="todate" v-model="formData.todate"
                  :max="new Date().toLocaleDateString('en-ca')" />
              </div>
              <div class="form-group">
                <label for="contnumber">Container No</label>
                <input type="text" id="contnumber" v-model="formData.contnumber"
                  placeholder="Generating Report for Specific Container(s)..." />
              </div>
              <div class="form-group">
                <button type="button" class="excel-button" @click="getAddInspectionDetails">Show Result</button>
                <button type="button" class="cancel-button1" @click="closeModal">Cancel</button>
              </div>
            </div>
            <div class="controls">
              <div class="search-container">
                <input v-model="searchQuery" placeholder="Search..." />
                <i class="fas fa-search search-icon"></i>
              </div>
              <div class="actions">
                <div>
                  Report No: <input type="text" id="rptno" v-model="rptno" />
                </div>
                <div>
                  <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                    <button class="print-button" title="Print" @click="generateReport">
                      <i class="fas fa-print"></i>Print
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-container" style="width: 100%;">
              <table class="transaction-table">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" @change="selectAll">
                    </th>
                    <th>
                      <span>Container</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('contnumber')"></i>
                    </th>
                    <th style="width:20px">
                      <span>ISO</span>
                    </th>
                    <th>
                      <span>Movement</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('MovementName')"></i>
                    </th>
                    <th>
                      <span>Place</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('LocationName')"></i>
                    </th>
                    <th>
                      <span>Date</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('inspDate')"></i>
                    </th>
                    <th>
                      <span>Applicant</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('applicantname')"></i>
                    </th>
                    <th>
                      <span>Account</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('accountname')"></i>
                    </th>
                    <th>
                      <span>Vessel</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('VesselName')"></i>
                    </th>
                    <th>
                      <span>Voyage</span>
                      <i class="filter-icon fas fa-sort" @click="sortByColumn('VoyageName')"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction) in paginatedTransactions" :key="transaction.reportid"
                    :class="{ 'editing': transaction === selectedTransaction }">
                    <td>
                      <input type="checkbox" v-model="checkedIds" :value="transaction.reportid">
                    </td>

                    <td>{{ transaction.contnumber }}</td>
                    <td>{{ transaction.iso_code }}</td>
                    <td>{{ transaction.MovementName }}</td>
                    <td>{{ transaction.LocationName }}</td>
                    <td>{{ formatDate(transaction.inspdate) }}</td>
                    <td>{{ transaction.applicantname }}</td>
                    <td>{{ transaction.accountname }}</td>
                    <td>{{ transaction.VesselName }}</td>
                    <td>{{ transaction.VoyageName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="bottom-controls" style="width: 100%;">
              <div class="rows-per-table">
                <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPagex === option }"
                  @click="setRowsPerPage(option)">
                  {{ option }}
                </button>
              </div>
              <div class="pagination">
                <button :disabled="currentPagex === 1" @click="changePagex(currentPagex - 1)">&laquo;</button>
                <button v-for="page in totalPagesx" :key="page" :class="{ 'active': page === currentPagex }"
                  @click="changePagex(page)">
                  {{ page }}
                </button>
                <button :disabled="currentPagex === totalPagesx" @click="changePagex(currentPagex + 1)">&raquo;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import * as cs from '../assets/js/commonscript.js'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      reports: [],
      party: JSON.parse(localStorage.getItem('party')),
      vessel: JSON.parse(localStorage.getItem('vessel')),
      voyage: JSON.parse(localStorage.getItem('voyage')),
      location: JSON.parse(localStorage.getItem('locations')),
      movement: JSON.parse(localStorage.getItem('movements')),
      rptno: '',
      formData: {
        applicantid: 0,
        accountid: 0,
        partyname: 0,
        vesselName: 0,
        voyageName: 0,
        locationname: 0,
        movementname: 0,
        fromdate: '',
        todate: '',
        contnumber: '',
      },
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      transactions: [],
      isModalVisible: false, // Control visibility of the modal
      isAddModalVisible: false,
      insptransactions: [],
      rowsPerPagex: 10,
      currentPagex: 1,
      checkedIds: [],
    };
  },
  computed: {
    filteredReports() {
      const query = this.searchQuery.toLowerCase();
      return this.reports.filter(report => {
        return Object.values(report).some(value =>
          String(value).toLowerCase().includes(query)
        );
      });
    },
    totalPages() {
      return Math.ceil(this.filteredReports.length / this.rowsPerPage);
    },
    paginatedReports() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredReports.slice(start, end);
    },

    filteredTransactions() {
      if (!this.insptransactions) {
        return
      }
      return this.insptransactions.filter(transaction => {
        return Object.values(transaction).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedTransactions() {
      if (!this.insptransactions) {
        return
      }
      const start = (this.currentPagex - 1) * this.rowsPerPagex;
      const end = start + this.rowsPerPagex;
      return this.filteredTransactions.slice(start, end);
    },
    totalPagesx() {
      if (!this.insptransactions) {
        return
      }
      return Math.ceil(this.filteredTransactions.length / this.rowsPerPagex);
    },
  },
  methods: {
    checkIsDate() {
      if (this.newContainer.fromdate != '') {
        if (cs.isvalidDate(this.newContainer.fromdate) == false) {
          useToast().error('Invalid From Date');
        }
      }
    },
    checkIsToDate() {
      if (this.newContainer.todate != '') {
        if (cs.isvalidDate(this.newContainer.todate) == false) {
          useToast().error('Invalid To Date');
        }
      }
    },
    async generateReport() {
      const checkedIds = this.checkedIds;
      console.log(checkedIds)
      if (checkedIds == "") {
        alert('Please select an Inspection');
        return;
      }
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        };
        const response = await axios.get('/add_inspection_to_report?reportid=' + checkedIds + '&reportno=' + this.rptno, config);
        console.log(response)
        //console.log('File uploaded successfully:', response.data);
        this.checkedIds = [];

      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async getAddInspectionDetails() {
      this.isLoading = true;
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      this.updateNewContainerIds(this.formData.partyname)
      console.log(this.formData.partyname)
      var fromDt = ''
      var toDt = ''
      if (this.formData.fromdate != '') {
        fromDt = cs.ConvertToISODate(this.formData.fromdate)
      }
      if (this.formData.todate != '') {
        toDt = cs.ConvertToISODate(this.formData.todate)
      }
      const transactionData = {
        applicant: this.formData.applicantid,
        account: this.formData.accountid,
        vessel: this.formData.vesselName,
        voyage: this.formData.voyageName,
        movement: this.formData.movementname,
        location: this.formData.locationname,
        fromdate: fromDt,
        todate: toDt,
        contnumber: this.formData.contnumber
      }
      if (transactionData.location == "") {
        transactionData.location = 0;
      }
      try {
        const response = await axios.get('/get_inspection?applicant=' + transactionData.applicant + '&account=' + transactionData.account + '&vessel=' + transactionData.vessel + '&voyage=' + transactionData.voyage + '&movement=' + transactionData.movement + '&location=' + transactionData.location + '&fromdate=' + transactionData.fromdate + '&todate=' + transactionData.todate + '&contnumber=' + transactionData.contnumber, config);
        //console.log(response.data[1], response.data[2]);
        this.insptransactions = response.data[2];
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async ClearReportFromInspection(reportid, rptno) {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const response = await axios.get('/clear_inspection_from_report?reportid=' + reportid + '&reportno=' + rptno, config);
        console.log(response)
        useToast().success(response.data[1]);

        this.getInspectionDetails(rptno)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async getInspectionDetails(reportno) {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      try {
        const response = await axios.get('/get_inspections_by_reportno?reportno=' + reportno, config);
        //console.log(response.data[1], response.data[2]);
        console.log(response)
        this.transactions = response.data[2];
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async openEditModal(reportno) {
      this.getInspectionDetails(reportno)
      this.isModalVisible = true; // Show the modal
    },
    openAddModal(reportno) {
      console.log(reportno)
      this.rptno = reportno;
      this.isAddModalVisible = true; // Show the modal
    },
    closeModal() {
      this.isModalVisible = false; // Hide the modal
      this.isAddModalVisible = false;
      this.transactions = null; // Reset selected transaction
    },
    updateNewContainerIds(value) {
      const AppAcc = cs.GetAppAccFromParty(value)
      this.formData.applicantid = AppAcc.applicantid
      this.formData.accountid = AppAcc.accountid
    },
    async getReportDetails() {
      this.isLoading = true;
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      this.updateNewContainerIds(this.formData.partyname)
      console.log(this.formData.partyname)
      const transactionData = {
        applicant: this.formData.applicantid,
        account: this.formData.accountid,
        vessel: this.formData.vesselName,
        voyage: this.formData.voyageName,
        movement: this.formData.movementname,
        location: this.formData.locationname,
        fromdate: this.formData.fromdate,
        todate: this.formData.todate,
        contnumber: this.formData.contnumber
      }
      if (transactionData.location == "") {
        transactionData.location = 0;
      }
      try {
        const response = await axios.get('/all_reports?applicant=' + transactionData.applicant + '&account=' + transactionData.account + '&vessel=' + transactionData.vessel + '&voyage=' + transactionData.voyage + '&movement=' + transactionData.movement + '&location=' + transactionData.location + '&fromdate=' + transactionData.fromdate + '&todate=' + transactionData.todate + '&contnumber=' + transactionData.contnumber, config);
        //console.log(response.data[1], response.data[2]);
        console.log(response)
        this.reports = response.data[2];
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchReports() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/all_reports', {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        this.reports = response.data[2];
        console.log(response)
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
    changePagex(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPagex = page;
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
    },
    async openPDF(filename) {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      const response = await axios.get('/ConvertImageToBlob?filename=' + filename, config, { responseType: 'blob' });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(pdfBlob);
      window.open(url);
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    selectAll(event) {
      this.checkedIds = event.target.checked
        ? this.paginatedTransactions.map(transaction => transaction.reportid)
        : [];
    },
  },
  mounted() {
    this.fetchReports();
  }
};
</script>



<style scoped>
.transaction-list {
  padding: 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

}

.container {
  width: 100%;
  background: #fff;
  padding: 5px 20px 5px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
  width: 22%;
  padding: 5px;
  float: left;
}

label {
  display: block;
  margin-bottom: 5px;
}

.port-page {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  align-items: center;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.add-button,
.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover,
.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: block;
  position: absolute;
  top: 25px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 4px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.transaction-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.transaction-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.port-table {
  width: 100%;
  border-collapse: collapse;
}

.port-table th,
.port-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.port-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table-button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table-button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  display: inline-block;
}

.excel-button,
.generate-button,
.cancel-button1 {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.excel-button {
  background-color: #17a2b8;
}

.cancel-button1 {
  background-color: #dc3545;
}

button:hover {
  opacity: 0.9;
}

.edit-button,
.save-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Overlay container */
.edit-overlay {
  position: fixed;
  /* Ensures the overlay stays above all other content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* Enables scrolling if the content exceeds viewport height */
  z-index: 9999;
  /* Ensures it appears above all other content */
}

/* Overlay content */
.edit-overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  /* Adjusted width for better fit */
  /*max-width: 1000px;*/
  /* Adjusted max-width */
  max-height: 90vh;
  /* Limits the height to prevent overflow */
  overflow-y: auto;
  /* Enables vertical scrolling within the content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 50px;
  /* Adds margin from the top */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

h2 {
  margin-top: 0;
}

.print-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.print-button:hover {
  background-color: rgb(61, 146, 231);
}
</style>
