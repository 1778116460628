export function myFoo(a, b) {
    return a + b;
}

export function GetAppAccFromParty(value) {
    const party = JSON.parse(localStorage.getItem('party'));
    const selectedParty = party.find(party => party.autoid === value);
    var AppAcc = { 'applicantid': 0, 'accountid': 0 }
    if (selectedParty.autoid !== 0) {
        if (selectedParty) {
            AppAcc = { 'applicantid': selectedParty.applicantId, 'accountid': selectedParty.accountId }
        } else {
            AppAcc = { 'applicantid': selectedParty.applicantId, 'accountid': selectedParty.accountId }
        }
    }
    return AppAcc;
}

export function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
}

export function contSCount(transarray, size) {
    var sCnt = 0;
    transarray.forEach((item) => {
        if (item.contsize == size) {
            sCnt = sCnt + 1
        }
    })
    return sCnt;
}

export function ConvertToISODate(dateStr) {
    var parts = dateStr.split("/")
    return `${parts[2]}-${parts[1]}-${parts[0]}`;    
}

export function isvalidDate(dateStr) {
    var dateval=ConvertToISODate(dateStr)
    return !isNaN(new Date(dateval));
    
}