<template>
  <div class="main">
    <div class="container-list">
      <h1 class="title">Container List</h1>

      <div v-if="isLoading">Loading Container list...</div>
      <div v-else-if="error">Error loading Container list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" @input="searchContainers" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Container">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="container-table">
            <thead>
              <tr>
                <th>
                  <span>Container Number</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('contnumber')"></i>
                </th>
                <th>
                  <span>ISO Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('iso_code')"></i>
                </th>
                <th>
                  <span>Container Size</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('contsize')"></i>
                </th>
                <th>
                  <span>Type</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('TypeText')"></i>
                </th>
                <th>
                  <span>Construction</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('construction')"></i>
                </th>
                <th>
                  <span>ISO Tare Weight</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isotarewt')"></i>
                </th>
                <th>
                  <span>ISO Gross Weight</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isogrosswt')"></i>
                </th>
                <th>
                  <span>Manufacture</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('manufacture')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newContainer.contnumber" placeholder="Container Number" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newContainer.iso_code" :options="isoCodes" label="isocode"
                    :reduce="isoCodes => isoCodes.isocode" placeholder="Select a ISO Code" attach="#table-container"
                    append-to-body selectOnTab="True" />
                </td>
                <td>
                  <select v-model="newContainer.contsize">
                    <option value="20">20</option>
                    <option value="40">40</option>
                  </select>
                </td>
                <td>

                  <v-select :clearable="false" v-model="newContainer.TypeText" :options="conttype" label="typetext"
                    :reduce="conttype => conttype.typetext" placeholder="Select Containertype" attach="#table-container"
                    append-to-body selectOnTab="True" />
                </td>
                <td>
                  <select v-model="newContainer.construction">
                    <option value="Steel" selected >Steel</option>
                    <option value="Aluminium">Aluminium</option>
                    <option value="FRP">FRP</option>
                  </select>
                </td>
                <td>
                  <input v-model="newContainer.isotarewt" type="number" placeholder="ISO Tare Weight" />
                </td>
                <td>
                  <input v-model="newContainer.isogrosswt" type="number" placeholder="ISO Gross Weight" />
                </td>
                <td>
                  <input v-model="newContainer.manufacture" placeholder="Manufacture" />
                </td>
                <td v-if="hasPermissions">
                  <button @click="saveContainer" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr v-for="container in paginatedContainers" :key="container.contid"
                :class="{ 'editing': container === selectedContainer }">
                <td v-if="container === selectedContainer">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="selectedContainer.contnumber" placeholder="Container Number" />
                </td>
                <td v-else>{{ container.contnumber }}</td>
                <td v-if="container === selectedContainer">
                  <v-select :clearable="false" v-model="selectedContainer.iso_code" :options="isoCodes" label="isocode"
                    :reduce="isoCode => isoCode.isocode" placeholder="Select a ISO Code" attach="#table-container"
                    append-to-body selectOnTab="True" />

                </td>
                <td v-else>{{ container.iso_code }}</td>
                <td v-if="container === selectedContainer">
                  <select v-model="selectedContainer.contsize">
                    <option value="20">20</option>
                    <option value="40">40</option>
                  </select>
                </td>
                <td v-else>{{ container.contsize }}</td>
                <td v-if="container === selectedContainer">
                  <v-select :clearable="false" v-model="newContainer.TypeText" :options="conttype" label="typetext"
                    :reduce="conttype => conttype.autoid" placeholder="Select Containertype" attach="#table-container"
                    append-to-body  selectOnTab="True"/>
                </td>
                <td v-else>{{ container.TypeText }}</td>

                <td v-if="container === selectedContainer">
                  <select v-model="selectedContainer.construction">
                    <option value="Steel">Steel</option>
                    <option value="Aluminium">Aluminium</option>
                    <option value="FRP">FRP</option>
                  </select>
                </td>
                <td v-else>{{ container.construction }}</td>
                <td v-if="container === selectedContainer">
                  <input v-model="selectedContainer.isotarewt" type="number" placeholder="ISO Tare Weight" />
                </td>
                <td v-else>{{ container.isotarewt }}</td>
                <td v-if="container === selectedContainer">
                  <input v-model="selectedContainer.isogrosswt" type="number" placeholder="ISO Gross Weight" />
                </td>
                <td v-else>{{ container.isogrosswt }}</td>
                <td v-if="container === selectedContainer">
                  <input v-model="selectedContainer.manufacture" placeholder="Manufacture" />
                </td>
                <td v-else>{{ sanitizeField(container.manufacture) }}</td>
                <td v-if="hasPermissions">
                  <button v-if="container === selectedContainer" @click="saveEditedContainer" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="container === selectedContainer" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editContainer(container)" class="edit-button"><i
                      class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      containers: [],
      nameError: '',
      tooltipVisible: false,
      showExportOptions: false,
      tooltipTimeout: null,
      isoCodes: [],
      conttype: [],
      paginatedContainers: [],
      isLoading: false,
      error: null,
      searchQuery: '',
      currentPage: 1,
      rowsPerPage: 10,
      pageRange: 5,
      hasPermissions: true,
      isAddingNew: false,
      isEditing: false, // For managing the edit state
      newContainer: {
        contnumber: '',
        contsize: '',
        conttype:'',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: 0,
        isogrosswt: 0,
        manufacture: '',
        is_active: false,
      },
      selectedContainer: null,
    };
  },
  watch: {
    'newContainer.contnumber'(contnumber) {
      if (this.isAddingNew) {
        this.checkContainerNumber(contnumber);
      }
    },
    'selectedContainer.contnumber'(contnumber) {
      if (this.isEditing) {
        this.checkContainerNumber(contnumber);
      }
    },
    searchQuery() {
      this.currentPage = 1; // Reset to the first page when search query changes
    },
    'newContainer.iso_code'(newCode) {
      this.updateTypeText(newCode, 'newContainer');
    },
    'selectedContainer.iso_code'(newCode) {
      if (this.isEditing) {
        this.updateTypeText(newCode, 'selectedContainer');
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.containers.length / this.rowsPerPage);
    },
    manufactureValue: {
      get() {
        return this.selectedContainer && this.selectedContainer.manufacture !== 'NULL' ? this.selectedContainer.manufacture : '';
      },
      set(value) {
        if (this.selectedContainer) {
          this.selectedContainer.manufacture = value;
        }
      }
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxPagesToShow = 5;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        pages.push(1);
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) pages.push('...');
        for (let i = start; i <= end; i++) pages.push(i);
        if (end < totalPages - 1) pages.push('...');
        if (totalPages > 1) pages.push(totalPages);
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    }
  },
  methods: {
    updateTypeText(selectedCode, containerType) {
      const selectedIso = this.isoCodes.find(iso => iso.isocode === selectedCode);
      if (selectedIso) {
        if (containerType === 'newContainer') {
          this.newContainer.TypeText = selectedIso.typetext; // Auto-fill TypeText          
          this.newContainer.contsize = selectedIso.size; // Auto-fill TypeText
          this.newContainer.conttype=selectedIso.typecode//
        } else if (containerType === 'selectedContainer') {
          this.selectedContainer.TypeText = selectedIso.typetext; // Auto-fill TypeText        
          this.selectedContainer.contsize = selectedIso.size; // Auto-fill TypeText
          this.selectedContainer.conttype=selectedIso.typecode//
        }
      } else {
        if (containerType === 'newContainer') {
          this.newContainer.TypeText = ''; // Clear if no match
          this.newContainer.contsize = ''; // Clear if no match
        } else if (containerType === 'selectedContainer') {
          this.selectedContainer.TypeText = ''; // Clear if no match
          this.newContainer.contsize = ''; // Clear if no match
        }
      }
    },
    async checkContainerNumber(contnumber) {
      if (contnumber.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const containerExists = this.containers.some(container =>
          container.contnumber.toLowerCase() === contnumber.toLowerCase() &&
          container.contid !== (this.isAddingNew ? this.newContainer.contid : this.selectedContainer?.contid)
        );
        if (containerExists) {
          this.nameError = 'Container number already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking container number:', err);
      }
    },
    sanitizeField(value) {
      return value === null || value === 'NULL' ? '' : value;
    },
    async fetchIsoCodes() {
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get('/container_iso', config);
        this.isoCodes = response.data[2];
      } catch (error) {
        console.error('Error fetching ISO codes:', error);
      }
    },
    async fetchContainerType() {
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get('/container_type', config);
        this.conttype = response.data[2];
        console.log(this.conttype)
      } catch (error) {
        console.error('Error fetching ISO codes:', error);
      }
    },
    async fetchContainers() {
      this.isLoading = true;
      const token = localStorage.getItem('access_token');

      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get('/container_list', config);
        this.containers = response.data[2];
        console.log(this.containers);
        this.totalPages = Math.ceil(this.containers.length / this.rowsPerPage);
        this.updatePaginatedContainers();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    updatePaginatedContainers() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      this.paginatedContainers = this.containers.slice(start, end);
    },
    setRowsPerPage(size) {
      this.rowsPerPage = size;
      this.totalPages = Math.ceil(this.containers.length / this.rowsPerPage);
      this.updatePaginatedContainers();
    },
    searchContainers() {
      const query = this.searchQuery.toLowerCase();
      if (query === '') {
        this.updatePaginatedContainers();
        return;
      }
      const filteredContainers = this.containers.filter(container =>
        Object.values(container).some(value =>
          value != null && value.toString().toLowerCase().includes(query)
        )
      );
      this.totalPages = Math.ceil(filteredContainers.length / this.rowsPerPage);
      this.currentPage = 1;
      this.paginatedContainers = filteredContainers.slice(0, this.rowsPerPage);
    },
    sortByColumn(column) {
      this.containers.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      this.updatePaginatedContainers();
    },
    openAddOverlay() {
      this.isAddingNew = true;
      this.isEditing = false; // Prevent editing if add is active
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.newContainer = {
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: 0,
        isogrosswt: 0,
        manufacture: '',
        is_active: false,
        contnumber: '',
        contid: 0
      };
    },
    async saveContainer() {
      const toast = useToast();
      const token = localStorage.getItem('access_token');
      try {
        if (this.nameError) {
          console.error('Name error:', this.nameError);
          toast.error('Please fix the errors before saving.');
          return;
        }
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          },
        };
        const selectedContType = this.conttype.find(ctype => ctype.typetext === this.newContainer.TypeText);
        if (selectedContType) {
          this.newContainer.conttype =selectedContType.typecode	
        }
        const data = new FormData();
        const x = JSON.stringify(this.newContainer)
        console.log(x)
        data.append('container', x)
        await axios.post('/create_container', data, config);
        toast.success('Container Created Successfully!');
        this.fetchContainers();
        this.cancelAdd();
      } catch (error) {
        this.error = error.message;
        toast.error(error);
      }
    },
    editContainer(container) {
      this.isEditing = true;
      this.isAddingNew = false; // Prevent adding if edit is active
      this.selectedContainer = container;
      this.newContainer = { ...container };
    },
    async saveEditedContainer() {
      const toast = useToast();
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = "Authorization token is missing";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          },
        };
        const data = new FormData();
        const x = JSON.stringify(this.selectedContainer)
        console.log(x)
        data.append('container', x)
        if (this.selectedContainer) {
          await axios.put(`/update_container`, data, config);
          toast.success('Container Updated Successfully!');
          await this.fetchContainers();
          this.cancelEdit();
        } else {
          this.error = "No container selected for editing";
        }
      } catch (error) {
        this.error = error.response?.data?.message || error.message;
      }
    },
    resetNewContainer() {
      this.newContainer = {
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: 0,
        isogrosswt: 0,
        manufacture: '',
      };
    },
    cancelEdit() {
      this.isEditing = false;
      this.selectedContainer = null;
      this.resetNewContainer();
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    async exportToCSV() {
      const headers = [
        "Container Size", "Container Type", "Type Text", "Construction",
        "ISO Code", "ISO Tare Weight", "ISO Gross Weight", "Manufacture",
        "Create Date", "Created By", "Last Modification Date", "Last Modification By",
        "Is Active", "Container Number", "Container ID"
      ];

      const rows = this.containers.map(container => [
        container.contsize,
        container.conttype,
        container.TypeText,
        container.construction,
        container.iso_code,
        container.isotarewt,
        container.isogrosswt,
        container.manufacture,
        container.create_date,
        container.created_by,
        container.last_modification_date,
        container.last_modification_by,
        container.is_active,
        container.contnumber,
        container.contid
      ]);

      let csvContent = "data:text/csv;charset=utf-8,"
        + [headers, ...rows].map(e => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "containers.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    async exportToPDF() {
      // PDF Export Logic here
    },
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
      this.updatePaginatedContainers();
    }
  },
  mounted() {
    this.fetchContainers();
    this.fetchIsoCodes();
    this.fetchContainerType();
  },
};
</script>






<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position above the button */
  left: 50%;
  margin-left: -60px;
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}


.container-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.add-button span {
  display: none;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150%;
}

.add-button:hover span {
  display: block;
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: block;
  top: 20%;
  right: 1%;

}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  display: inline-block;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.container-table {
  width: 100%;
  border-collapse: collapse;
}

.container-table th,
.container-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.container-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.container-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.new-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table span {
  margin-right: 10px;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.export-container {
  position: relative;
  /* Add this */
}


.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}
</style>
