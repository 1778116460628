<template>
  <div class="main">
    <div class="container-list">
      <h1 class="title">Advanced Container List</h1>
      <div class="controls">
        <div class="filter-container">
          <select v-model="selectedLocation">
            <option value="">Select Location</option>
            <option v-for="location in location" :key="location.locationid" :value="location">{{ location.Name }}
            </option>
          </select>

          <select v-model="selectedVCN">
            <option value="">Select VCN</option>
            <option v-for="vcn in vcnList" :key="vcn.vcnID" :value="vcn">{{ vcn.vcnName }}({{ vcn.VesselName }})</option>
          </select>

          <select v-model="selectedMovementType">
            <option value="">Select Movement Type</option>
            <option value="IMP">Import</option>
            <option value="EXP">Export</option>
          </select>
        </div>
      </div>
      <div v-if="isLoading">Loading Container list...</div>
      <div v-else-if="error">Error loading Container list: {{ error }}</div>

      <div v-else>

        <div>
          <div v-if="isFilterSelected" class="search-container">
            <input ref="inputContainerNumber" v-model="searchContainerNumber" placeholder="Search Container Number"
              @keyup.enter="searchAndEdit" />
          </div>
        </div>

        <div v-if="isFilterSelected" class="table-container">
          <div v-if="!filteredContainers.length && !isLoading && !error" class="no-data">
            No data found
          </div>

          <table v-else class="movement-table">
            <thead>
              <tr>
                <th class="field-100">
                  <span>Container</span>
                </th>
                <th class="field-100">
                  <span>Short Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('short_name')"></i>
                </th>
                <th class="field-100">
                  <span>Vessel Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('VesselName')"></i>
                </th>
                <th class="field-100">
                  <span>Voyage Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('VoyageName')"></i>
                </th>
                <th class="field-100">
                  <span>Movement Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('MovementName')"></i>
                </th>
                <th class="field-100">
                  <span>Location Name</span>
                </th>
                <th class="field-100">
                  <span>Seal No</span>
                </th>
                <th class="field-100" v-if="hasPermissions && shouldShowISPTDate">
                  <span>ISPT Date</span>
                </th>
                <th class="field-100" v-if="hasPermissions && shouldShowTime">
                  <span>Time</span>
                </th>
                <th class="field-100" v-if="shouldShowGrossWeight">
                  <span>Gross Weight</span>
                </th>
                <th class="field-100" v-if="shouldShowCFSID">
                  <span>CFS Name</span>
                </th>
                <th class="field-100" v-if="shouldShowPOL">
                  <span>POL</span>
                </th>
                <th class="field-100" v-if="shouldShowPOD">
                  <span>POD</span>
                </th>
                <th class="field-100" v-if="shouldShowFPD">
                  <span>FPD</span>
                </th>
                <th class="field-60">
                  <span>HAZ STAT</span>
                </th>
                <th class="field-120">
                  <span>Note</span>
                </th>
                <th class="field-120">
                  <span>Image</span>
                </th>
                <th class="field-100" v-if="hasPermissions && shouldShowSoundDamage">
                  <span>Sound/Damage</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(container) in paginatedContainers" :key="container.autoID"
                :class="{ 'editing': container === selectedContainer }">
                <td v-if="container === selectedContainer">
                  <input class="field-100" ref="inputcontnumber" v-model="newContainer.contnumber"
                    placeholder="Container Number" />
                </td>
                <td v-else>{{ container.contnumber }}</td>
                <td v-if="container === selectedContainer">
                  <select class="field-100" v-model="container.short_name" @change="handleChange">
                    <option v-for="party in linkParties" :key="party.short_name" :value="party.short_name">
                      {{ party.short_name }}
                    </option>
                  </select>

                </td>
                <td v-else>{{ container.short_name }}</td>
                <td v-if="container === selectedContainer">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.vesselID" :options="vessels"
                    label="Name" :reduce="vessel => vessel.Vesselid" placeholder="Select a Vessel Name"
                    attach="#table-container" append-to-body selectOnTab="True" />
                </td>
                <td v-else>{{ container.VesselName }}</td>
                <td v-if="container === selectedContainer">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.VoyId" :options="voyage"
                    label="Name" :reduce="voyage => voyage.Voyageid" placeholder="Select a Voyage Name"
                    selectOnTab="True" append-to-body />
                </td>
                <td v-else>{{ container.VoyageName }}</td>
                <td v-if="container === selectedContainer">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.movementID"
                    :options="movementOptions" label="Name" :reduce="movement => movement.Movid"
                    placeholder="Select Movement Name" selectOnTab="True" append-to-body />
                </td>
                <td v-else>{{ container.MovementName }}</td>
                <td v-if="container === selectedContainer">
                  <v-select class="field-100" :clearable="false" v-model="newContainer.inspectionplace"
                    :options="location" label="Name" :reduce="location => location.locationid"
                    placeholder="Select a Location Name" selectOnTab="True" append-to-body />
                </td>
                <td v-else>{{ container.LocationName }}</td>
                <td v-if="container === selectedContainer">
                  <input class="field-100" v-model="newContainer.sealNo" placeholder="Seal No" />
                </td>
                <td v-else>{{ container.sealNo }}</td>
                <td v-if="container === selectedContainer && shouldShowISPTDate">
                  <input class="field-100" type="text" v-model="newContainer.inspDate" v-on:blur="checkIsDate()" />
                </td>
                <td v-else-if="shouldShowISPTDate">{{ container.inspDate }}</td>
                <td v-if="container === selectedContainer && shouldShowTime">
                  <input class="field-60" type="text" v-model="newContainer.inspTime" />
                </td>
                <td v-else-if="shouldShowTime">{{ container.inspTime }}</td>
                <td v-if="container === selectedContainer && shouldShowGrossWeight">
                  <input class="field-100" type="number" v-model="newContainer.GrossWt" placeholder="Gross Weight" />
                </td>
                <td v-else-if="shouldShowGrossWeight">{{ container.GrossWt }}</td>
                <td v-if="container === selectedContainer && shouldShowCFSID">
                  <v-select class="field-100" :clearable="false" style="width: 150px;" v-model="newContainer.cfsID"
                    :options="cfs_location" label="Name" :reduce="location => location.locationid"
                    placeholder="Select CFS" selectOnTab="True" append-to-body />
                </td>
                <td v-else-if="shouldShowCFSID">{{ container.cfsID }}</td>
                <td v-if="container === selectedContainer && shouldShowPOL">
                  <v-select class="field-100" :clearable="false" style="width: 150px;" v-model="newContainer.pol"
                    :options="ports" label="name" :reduce="port => port.portid" placeholder="Select POL"
                    selectOnTab="True" append-to-body />
                </td>
                <td v-else-if="shouldShowPOL">{{ container.pol }}</td>
                <td v-if="container === selectedContainer && shouldShowPOD">
                  <v-select class="field-100" :clearable="false" style="width: 150px;" v-model="newContainer.pod"
                    :options="ports" label="name" :reduce="port => port.portid" placeholder="Select POD"
                    selectOnTab="True" append-to-body />
                </td>
                <td v-else-if="shouldShowPOD">{{ container.pod }}</td>
                <td v-if="container === selectedContainer && shouldShowFPD">
                  <v-select class="field-100" :clearable="false" style="width: 150px;" v-model="newContainer.fpd"
                    :options="ports" label="name" :reduce="port => port.portid" placeholder="Select FPD"
                    selectOnTab="True" append-to-body />
                </td>
                <td v-else-if="shouldShowFPD">{{ container.fpd }}</td>
                <td v-if="container === selectedContainer">
                  <v-select class="field-60" :clearable="false" style="width: 60px;height: 40px;"
                    v-model="newContainer.hazStat" :options="hazStatOptions" label="label"
                    selectOnTab="True" :reduce="option => option.value" placeholder="Select Hazard Status" append-to-body />
                </td>
                <td v-else>{{ container.hazStat }}</td>
                <td v-if="container === selectedContainer">
                  <textarea class="field-100" v-model="newContainer.note" placeholder="Note" rows="4"></textarea>
                </td>
                <td v-else>{{ container.note }}</td>                
                <td><input type="file" @change="onFileChange" ref="fileAdd" class="btn btn-default" multiple></td>
                <td v-if="hasPermissions">
                  <div class="sound-damage-cancel-buttons field-100"
                    v-if="container === selectedContainer && shouldShowSoundDamage">
                    <input type="radio" id="sound" value="sound" v-model="newContainer.damage"
                      @keydown.enter="handleCondition('Sound')">
                    <label for="sound">Sound</label>
                    <input type="radio" id="damage" value="damage" v-model="newContainer.damage"
                      @keydown.enter="handleCondition('Damage')">
                    <label for="damage">Damage</label>
                    <button @click="cancelEdit" class="cancel-button"><i class="fas fa-times"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!filteredContainers.length && !isLoading && !error" class="no-data">

        </div>
        <div v-else>
          <div v-if="isFilterSelected">
            <div class="bottom-controls">
              <div class="rows-per-table">
                <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
                  @click="setRowsPerPage(option)">
                  {{ option }}
                </button>
              </div>
              <div class="pagination">
                <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
                <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
                  :class="{ active: page === currentPage }">
                  {{ page }}
                </button>
                <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div v-if="showOverlay" class="overlay">
        <div class="overlay-content">
          <h2>Damage Details</h2>
          <form @submit.prevent="saveDamageDetails">
            <div class="overlay-form-group">
              <label for="location">Location:</label>
              <v-select :clearable="false" style="width: 250px;" v-model="damageDetails.location"
                :options="locations" label="Name" :reduce="loc => loc.id" class="overlay-form-select"
                placeholder="Select Location" selectOnTab="True" />
              <label for="position">Position:</label>
              <v-select :clearable="false" style="width: 250px;" id="position" v-model="damageDetails.position"
                :options="positions" :reduce="pos => pos.id" placeholder="Select Position" label="name"
                class="overlay-form-select" selectOnTab="True" />

            </div>
            <div class="overlay-form-group">
              <label for="damageFrom">Damage From:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" placeholder="Select Damage From"
                v-model="damageDetails.damageFrom" :options="FromSec1" :reduce="fromsec => fromsec.id" label="name"
                class="overlay-form-select" selectOnTab="True" append-to-body />

              <label for="damageFrom">&</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" v-model="damageDetails.damageFromAnd"
                :options="FromSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                selectOnTab="True" append-to-body />

            </div>

            <div class="overlay-form-group">
              <label for="damageTo">Damage To:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageTo"
                :options="ToSec1" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                append-to-body placeholder="Select Damage To" selectOnTab="True" />

              <label for="damageTo">&:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageToAnd"
                :options="ToSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                selectOnTab="True" append-to-body />

            </div>

            <div class="overlay-form-group">
              <label style="width:100px" for="component">Component:</label>
              <v-select :clearable="false" style="width: 120px;" id="component" v-model="damageDetails.component"
                :options="components" label="description" :reduce="component => component.id" class="overlay-form-input"
                placeholder="Select Component" selectOnTab="True" />

              <label for="damageType">Damage Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="damageType" v-model="damageDetails.damageType"
                :options="damageTypes" label="description" :reduce="damageType => damageType.id"
                class="overlay-form-input" placeholder="Select Damage Type " selectOnTab="True" />

              <label for="repairType">Repair Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="repairType" v-model="damageDetails.repairType"
                :options="repairTypes" label="description" :reduce="repairType => repairType.id"
                class="overlay-form-input" placeholder="Select Repair Type" selectOnTab="True" />
            </div>
            <div class="overlay-form-group">
              <label for="length">Length:</label>
              <input style="width: 60px;" type="number" value="0" id="length" v-model="damageDetails.length"
                class="overlay-form-input" />
              <label for="breadth">Breadth:</label>
              <input style="width: 60px;" type="number" id="breadth" v-model="damageDetails.breadth"
                class="overlay-form-input" />
              <label for="area">Area:</label>
              <input style="width: 60px;" type="number" id="area" value="0" v-model="damageDetails.area"
                class="overlay-form-input" />
              <label for="quantity">Quantity:</label>
              <input style="width: 60px;" type="number" id="quantity" value="0" v-model="damageDetails.quantity"
                class="overlay-form-input" />
            </div>
            <div class="overlay-form-group">
              <label for="damenote">Note:</label>
              <textarea style="width: 80%;" type="text" value="0" id="damenote" v-model="damageDetails.note"
                class="overlay-form-input"></textarea>
            </div>

            <div class="overlay-form-group">
              <label for="dameimage">Image:</label>
              <input type="file" @change="onDameFileChange" ref="fileDamAdd" />
            </div>


            <button type="submit">Save</button>
            <button class="cancel-button" @click="closeOverlay">Cancel</button>
          </form>

          <!-- Table to display saved damage details -->
          <div v-if="damageDetailsList.length > 0">
            <table class="damage-details-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Position</th>
                  <th>Damage From</th>
                  <th>Damage To</th>
                  <th>Component</th>
                  <th>Damage Type</th>
                  <th>Repair Type</th>
                  <th>Length</th>
                  <th>Breadth</th>
                  <th>Area</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(detail, index) in damageDetailsList" :key="index">
                  <td>{{ detail.location.Name }}</td>
                  <td>{{ detail.position.name }}</td>
                  <td>{{ detail.damageFrom.name }}</td>
                  <td>{{ detail.damageTo.name }}</td>
                  <td>{{ detail.component }}</td>
                  <td>{{ detail.damageType }}</td>
                  <td>{{ detail.repairType }}</td>
                  <td>{{ detail.length }}</td>
                  <td>{{ detail.breadth }}</td>
                  <td>{{ detail.area }}</td>
                  <td>{{ detail.quantity }}</td>
                </tr>
              </tbody>
            </table>
            <button class="done-button" @click="done">Done</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import * as cs from '../assets/js/commonscript.js'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      containers: [],
      linkParties: JSON.parse(localStorage.getItem('party')), // New data property for link parties
      accounts: JSON.parse(localStorage.getItem('accounts')),
      vessels: JSON.parse(localStorage.getItem('vessel')),
      voyage: JSON.parse(localStorage.getItem('voyage')),
      ports: JSON.parse(localStorage.getItem('ports')),
      movementOptions: JSON.parse(localStorage.getItem('movements')),
      isLoading: false,
      error: null,
      hasPermissions: true,
      searchContainerNumber: '',
      rowsPerPage: 10,
      currentPage: 1,
      hazStatOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ],
      selectedContainer: null,
      newContainer: {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: 0,
        accountID: 0,
        vesselID: 0,
        VoyId: 0,
        containerID: 0,
        contnumber: '',
        movementID: 0,
        inspectionplace: 0,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        GrossWt: 0,
        note: null,
        hazStat: 'N',
        short_name: '', // Added short_name
        damage: '', // Added damage property
        isptDate: this.getCurrentDate(),
        inspTime: ''
      },
      sortDirection: {},
      selectedLocation: '',
      selectedVCN: '',
      selectedMovementType: '',
      location: JSON.parse(localStorage.getItem('locations')) || [],
      vcnList: [],
      cfs_location: JSON.parse(localStorage.getItem('cfs_location')) || [],

      locations: [
        { id: 0, name: 'Select One' },
        { id: 1, Name: 'EXT' },
        { id: 2, Name: 'INT' },],
      positions: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Left' },
        { id: 2, name: 'Front' },
        { id: 3, name: 'Right' },
        { id: 4, name: 'Top' },
        { id: 5, name: 'Rear' },
        { id: 6, name: 'Interior' },
        { id: 7, name: 'Under Structure' },
        { id: 8, name: 'Exterior' },
      ],
      FromSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      FromSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],

      ToSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      ToSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],

      damageDetails: {
        location: 0,
        position: 0,
        damageFrom: 0,
        damageFromAnd: 0,
        damageTo: 0,
        damageToAnd: 0,
        component: 0,
        damageType: 0,
        repairType: 0,
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0,
        note: '',
        Image: null
      },
      
      imagefiles: [],
      damageTypes: [],
      repairTypes: [],
      components: [],

      damageDetailsList: [],
      damageDbList: [],
      showOverlay: false,
    };
  },
  created() {
    //this.fetchLocations();
    this.fetchVcnList();
    this.fetchData();

    let keysPressed = {};
    document.addEventListener('keydown', (e) => {
      keysPressed[e.key] = true;
      if (keysPressed['Alt'] && e.key == 's') {
        this.newContainer.damage = 'sound'
        this.handleCondition('Sound')
      }
      else if (keysPressed['Alt'] && e.key == 'd') {
        this.newContainer.damage = 'damage'
        this.handleCondition();
      }
      document.addEventListener('keyup', (e) => {
        delete keysPressed[e.key];
      });
    });
  },
  computed: {
    ...mapGetters('applicants', ['applicants']),
    filteredContainers() {
      return this.containers.filter(container => {
        const matchesSearchQuery = container.contnumber.toLowerCase().includes(this.searchContainerNumber.toLowerCase());
        const matchesLocation = this.selectedLocation ? container.LocationName === this.selectedLocation.Name : true;
        const matchesVCN = this.selectedVCN ? container.vcnName === this.selectedVCN.vcnName : true;
        const matchesMovementType = this.selectedMovementType ? container.movetype === this.selectedMovementType : true;
        return matchesSearchQuery && matchesLocation && matchesVCN && matchesMovementType;
      });
    },
    shouldShowISPTDate() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowTime() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowGrossWeight() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowCFSID() {
      return ['23', '24', '25', '42', '43'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOL() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowFPD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowSoundDamage() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    paginatedContainers() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredContainers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredContainers.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage; // assuming you have a currentPage data property
      const maxPagesToShow = 10;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        // Show all pages if they are fewer than the maxPagesToShow
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        // Always show the first page
        pages.push(1);

        // Show a range around the current page
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) {
          pages.push('...');
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages - 1) {
          pages.push('...');
        }

        // Always show the last page
        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    },
    maxDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    isFilterSelected() {
      return (
        this.selectedLocation !== '' ||
        this.selectedVCN !== '' ||
        this.selectedMovementType !== ''
      );
    },
  },
  watch: {
    filteredContainers(newValue) {
      const toast = useToast();
      if (newValue.length === 0 && !this.isLoading && !this.error) {
        toast.error('No data found');
      }
    },
    selectedVCN(newVCN) {
      if (newVCN && newVCN.vcnID) {
        this.fetchContainers(newVCN.vcnID);
      }  // Fetch without filter if no VCN is selected
    },
    searchContainerNumber() {
      this.currentPage = 1;
    },
  },
  methods: {
    checkIsDate() {
      if (this.newContainer.inspDate != '') {
        if (cs.isvalidDate(this.newContainer.inspDate) == false) {
          //this.formData.fromdate
          useToast().error('Invalid Date');
        }
      }
    },
    fetchData() {
      const accessToken = localStorage.getItem('access_token');

      axios.get('/active_damage_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const damageTypesResponse = response.data[2];
          this.damageTypes = damageTypesResponse.map(damageType => ({
            id: damageType.autoid,
            name: damageType.shortName,
            description: damageType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('/active_repair_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const repairTypesResponse = response.data[2];
          this.repairTypes = repairTypesResponse.map(repairType => ({
            id: repairType.autoid,
            name: repairType.shortName,
            description: repairType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('/active_component_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const componentsResponse = response.data[2];
          this.components = componentsResponse.map(component => ({
            id: component.autoid,
            name: component.shortName,
            description: component.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });
    },
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    async fetchContainers(vcnID = '') {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get(`/advance_container_list/${vcnID}`, config);
        this.containers = response.data[2];
      } catch (error) {
        console.error('Error fetching container list:', error);
        this.error = 'Error fetching container list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    updateNewContainerIds(value) {
      const selectedParty = this.linkParties.find(party => party.short_name === value);
      if (selectedParty) {
        this.newContainer.applicantID = selectedParty.applicantId;
        //console.log(selectedParty.applicantId);
        this.newContainer.accountID = selectedParty.accountId;
        //console.log(selectedParty.accountId);
      } else {
        this.newContainer.applicantID = 0;
        //console.log('hello')
        this.newContainer.accountID = 0;
      }
    },
    validateRequiredFields() {
      if (!this.newContainer.contnumber || !this.newContainer.VesselName || !this.newContainer.VoyageName) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields.');
        return false;
      }
      return true;
    },
    handleChange(event) {
      if (!this.validateRequiredFields()) {
        return;
      }
      const selectedShortName = event.target.value;
      this.updateNewContainerIds(selectedShortName);
    },
    async fetchLocations() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/location_list/', config);
        this.location = response.data[2];
      } catch (error) {
        console.error('Error fetching locations:', error);
        this.error = 'Error fetching locations. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    async fetchVcnList() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/active_vcn_list/', config);
        this.vcnList = response.data[2];
      } catch (error) {
        console.error('Error fetching VCN list:', error);
        this.error = 'Error fetching VCN list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    resetFilters() {
      this.selectedLocation = '';
      this.selectedVCN = '';
      this.selectedMovementType = '';
      this.searchContainerNumber = '';
    },
    cancelEdit() {
      this.selectedContainer = null;
      this.resetNewContainer();
    },
    async handleCondition(conditionType) {
      if (!this.newContainer.contnumber || !this.newContainer.vesselID || !this.newContainer.VoyId || !this.newContainer.movementID || !this.newContainer.inspectionplace || !this.newContainer.inspDate || !this.newContainer.inspTime || !this.newContainer.hazStat) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields before selecting Sound or Damage.');
        return;
      }
      if (!this.newContainer.damage) {
        useToast().error('Please select Sound or Damage.');
        return;
      }
      
      if (conditionType === 'Sound') {
        this.SaveTransactionDate('Sound')
      } else if (conditionType === 'Damage') {
        this.openDamageOverlay();
      }
      /*
      const conditionId = conditionType === 'Sound' ? 2 : 1;
      //console.log(conditionType);
      const transactionData = {
        containerId: this.newContainer.containerID || 0,
        applicant: this.newContainer.applicantID || 0,
        account: this.newContainer.accountID || 0,
        vesselld: this.newContainer.vesselID || 0,
        voyage: this.newContainer.VoyId || 0,
        movementId: this.newContainer.movementID || 0,
        seal: this.newContainer.sealNo || '',
        inspdate: this.newContainer.inspDate || this.getCurrentDate(),
        insptime: this.newContainer.inspTime || '',
        tolocation: this.newContainer.cfsID || 0,
        conditionid: conditionId,
        condition: conditionType,
        note: this.newContainer.note || '',
        cargowt: this.newContainer.GrossWt || 0,
        hazstat: this.newContainer.hazStat || 'N',
        inspectionplace: this.newContainer.inspectionplace || 0,
        loadport: this.newContainer.pol || 0,
        destnport: this.newContainer.pod || 0,
        transport: this.newContainer.fpd || 0,
      };

      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const data = new FormData();
        const x = JSON.stringify(transactionData)
        data.append('transaction', x)
        const response = await axios.post('/create_transaction', data, config);
        if (response.data[0] === 0) {
          // Display success message using the second element of the response
          useToast().error(response.data[1]);
        } else {
          this.searchContainerNumber = '';
          useToast().success('Created Succesful');
          //const focuscontnumberfld = this.$refs.inputContainerNumber[0]; // Assuming it's a ref array, hence [0]
          //focuscontnumberfld.focus(); 
        }

        await this.fetchContainers(this.selectedVCN ? this.selectedVCN.vcnID : '');
      } catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      } */
    },
    
    openDamageOverlay() {
      this.showOverlay = true;
    },    
    closeOverlay() {
      this.showOverlay = false;
    },    
    onFileChange(e) {
      var selectedFiles = e.target.files;
      for (var i = 0; i < selectedFiles.length; i++) {
        this.imagefiles.push(selectedFiles[i]);
      }
    },
    saveDamageDetails() {
      //console.log(this.damageDetails)
      this.damageDetailsList.push({ ...this.damageDetails });
      const dmg = {
        damlocationid: this.damageDetails.location['id'] || 0,
        positionid: this.damageDetails.position['id'] || 0,
        damageFrom: this.damageDetails.damageFrom['id'] || 0,
        damageFromAnd: this.damageDetails.damageFromAnd['id'] || 0,
        damageTo: this.damageDetails.damageTo['id'] || 0,
        damageToAnd: this.damageDetails.damageToAnd['id'] || 0,
        component: this.damageDetails.component || 0,
        damageType: this.damageDetails.damageType || 0,
        repairType: this.damageDetails.repairType || 0,
        length: this.damageDetails.length || 0,
        breadth: this.damageDetails.breadth || 0,
        area: this.damageDetails.area || 0,
        quantity: this.damageDetails.quantity || 0,
        note:this.damageDetails.note||'',
        Image: this.$refs.fileDamAdd.files[0]
      };

      this.damageDbList.push(dmg);
      // Clear the form fields after saving
      this.damageDetails = {
        location: 0,
        position: 0,
        damageFrom: 0,
        damageTo: 0,
        component: 0,
        damageType: 0,
        repairType: 0,
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0,
        note:'',
        Image:null
      };
    },
    async done() {
      //console.log(this.damageDbList)
      this.SaveTransactionDate('Damage')
      this.closeOverlay();
    },
    async SaveTransactionDate(conditionType) {
      //console.log(this.newContainer)

      const conditionId = conditionType === 'Sound' ? 2 : 1;
      const transactionData = {
        containerId: this.newContainer.containerID || 0,
        containerNumber: this.newContainer.contnumber || '',
        applicant: this.newContainer.applicantID || 0,
        account: this.newContainer.accountID || 0,
        vesselld: this.newContainer.vesselID || 0,
        voyage: this.newContainer.VoyId || 0,
        movementId: this.newContainer.movementID || 0,
        locationId: this.newContainer.locID || 0,
        cfsId: this.newContainer.cfsID || 0,
        cfsName: this.newContainer.cfsName, // Use saved name
        seal: this.newContainer.sealNo || '',
        inspdate: cs.ConvertToISODate(this.newContainer.inspDate || this.getCurrentDate()),
        insptime: this.newContainer.inspTime || '',
        conditionid: conditionId,
        inspectionplace: this.newContainer.locID,
        condition: conditionType,
        note: this.newContainer.note || '',
        cargowt: this.newContainer.GrossWt || 0,
        hazstat: this.newContainer.hazStat || 'N',
        loadport: this.newContainer.pol || 0,
        loadPortName: this.newContainer.loadPortName, // Use saved name
        destnport: this.newContainer.pod || 0,
        destnPortName: this.newContainer.destnPortName, // Use saved name
        transport: this.newContainer.fpd || 0,
        transportName: this.newContainer.transportName, // Use saved name
      };
      //console.log(transactionData)
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'multipart/form-data'
          },
        };
        const data = new FormData();
        const x = JSON.stringify(transactionData)
        data.append('transaction', x)
        
        const fileInput = this.$refs.fileAdd[0];
        
        for (const file of fileInput.files) {
          data.append("files", file);
        }
        
        if (conditionId == 1) {
          const damagedt = JSON.stringify(this.damageDbList)
          //console.log(damagedt);
          data.append('damageData', damagedt)
        }

        const response = await axios.post('/create_transaction', data, config);
        if (response.data[0] === 0) {
          useToast().error(response.data[1]);
        } else {
          useToast().success('Created Succesful');
          this.resetNewContainer();
        }

      } catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }
    },
    resetNewContainer() {
      this.newContainer = {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: 0,
        accountID: 0,
        vesselID: 0,
        VoyId: 0,
        containerID: 0,
        contnumber: '',
        movementID: 0,
        inspectionplace: 0,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        GrossWt: 0,
        note: null,
        hazStat: 'N',
        short_name: '', // Added short_name
        damage: '', // Added damage property
        isptDate: '',
        inspTime: ''
      };      
      this.damageDetails = {
          location: 0,
          position: 0,
          damageFrom: 0,
          damageFromAnd: 0,
          damageTo:0,
          damageToAnd:0,
          component:0,
          damageType: 0,
          repairType: 0,
          length: 0,
          breadth: 0,
          area: 0,
          quantity: 0
        },
        this.damageDetailsList = [],
        this.damageDbList = []
        this.$refs.fileAdd = null
    },
    searchAndEdit(event) {
      if (event.key === 'Enter') {
        const foundContainer = this.containers.find(container => container.contnumber === this.searchContainerNumber);
        if (foundContainer) {
          // Determine the page where the container is located
          const containerIndex = this.filteredContainers.indexOf(foundContainer);
          if (containerIndex !== -1) {
            const page = Math.ceil((containerIndex + 1) / this.rowsPerPage);
            this.currentPage = page; // Update the current page
          }

          this.editContainer(foundContainer);
        }
      }
    },
    editContainer(container) {

      this.selectedContainer = container;
      //this.$nextTick(() => {
      //  const focuscontnumberfld = this.$refs.inputcontnumber[0]; // Assuming it's a ref array, hence [0]
      //  focuscontnumberfld.focus();  // Set focus on the input field
      //});
      this.selectedContainer.hazStat = 'N';
      this.selectedContainer.inspDate = this.getCurrentDate();
      this.newContainer = { ...container };
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1;
    },
    sortByColumn(column) {
      const currentDirection = this.sortDirection[column] || 'asc';
      const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';

      this.sortDirection[column] = newDirection;
      this.containers.sort((a, b) => {
        if (a[column] < b[column]) return newDirection === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return newDirection === 'asc' ? 1 : -1;
        return 0;
      });
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
  },
};
</script>


<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
}



.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-container select {
  flex: 1;
  margin-right: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #fff;
  font-size: 0.875rem;
  color: #333;
}

.filter-container select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}

.filter-container select option {
  background-color: #fff;
  color: #333;
}

.filter-container select option:hover {
  background-color: #f5f5f5;
}

.search-container {
  display: flex;
  justify-content: flex-start;
  /* Aligns the search bar to the left */
  margin-top: 1rem;
  padding: 10px;
  /* Adds space above the search bar */
}

.search-container input {
  flex: 1;
  /* Makes the input take up available space */
  max-width: 300px;
  /* Set a maximum width for the input */
  padding: 0.5rem;
  /* Adds padding inside the input */
  border: 1px solid #ccc;
  /* Light gray border */
  border-radius: 0.25rem;
  /* Rounded corners */
  font-size: 0.875rem;
  /* Font size */
  color: #333;
  /* Text color */
  background-color: #fff;
  /* White background */
  transition: border-color 0.2s;
  /* Smooth transition for border color */
}

.search-container input:focus {
  outline: none;
  /* Removes default outline */
  border-color: #66afe9;
  /* Blue border on focus */
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  /* Blue shadow on focus */
}

.search-container input::placeholder {
  color: #aaa;
  /* Light gray placeholder text */
}

.actions {
  display: flex;
  align-items: center;
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-options {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
  top: 40px;
  border-radius: 10px;
}

.export-button:hover .export-options {
  display: block;
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.movement-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.movement-table th,
.movement-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.movement-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.movement-table tr:hover {
  background-color: #f1f1f1;
}

.successful-task {
  background-color: #e0ffe0;
  /* Light green background for successful tasks */
}

.add-row {
  background-color: #e8f0fe;
  /* Light blue background for the add row */
}

.sound-damage-cancel-buttons {
  display: flex;
  flex-direction: column;
  /* Align items vertically */
  align-items: flex-start;
  /* Align items to the start (left) */
  margin-top: 10px;
}

.sound-damage-cancel-buttons input[type="radio"] {
  margin-bottom: 5px;
  /* Space between radio buttons */
}

.sound-damage-cancel-buttons button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 5px;
  /* Space above each button */
  cursor: pointer;
  border-radius: 4px;
}

.sound-damage-cancel-buttons button.cancel-button {
  background-color: #f44336;
  /* Red */
}

.sound-damage-cancel-buttons button:hover {
  opacity: 0.8;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table span {
  margin-right: 10px;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  /* Add background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* Allow scrolling if content overflows */
  z-index: 1000;
  /* Ensure overlay is on top of other content */
}

/* Overlay content styling */
.overlay-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  /* Allow more width */
  max-height: 90%;
  /* Restrict height to avoid overflow */
  overflow-y: auto;
  /* Scroll if content overflows vertically */
  z-index: 1001;
  /* Ensure content is above overlay background */
}

/* Overlay form group styling */
/* Overlay form group styling */
.overlay-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* Add space between form groups */
  gap: 5px;
  /* Add space between fields */
}

/* Styling for labels to ensure alignment */
.overlay-form-group label {
  margin-right: 2px;
  width: 70px;
  /* Set a fixed width for labels */
}

/* Styling for input and select elements to take available space */

.overlay-form-group .overlay-form-select {
  width: 350px;
  /* adjust the width to your desired size */
  font-size: 12px;
  /* adjust the font size to your desired size */
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #f68677;
}

/* Table styling */
.damage-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.damage-details-table th {
  padding: 10px 15px;
  background-color: #f0f0f0;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  text-align: left;
  border-bottom: 2px solid #ccc;
}

.damage-details-table td {
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
}

/* Button styling */
.done-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
}

.done-button:hover {
  background-color: #218838;
}
</style>
