<template>
  <div class="main">
    <div class="body-content" >
      <h1 class="title">Report List</h1>

      <div v-if="isLoading">Loading Report list...</div>
      <div v-else-if="error">Error loading Report list: {{ error }}</div>
      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
        </div>
        <div class="table-container">
          <table class="vessel-table table-danger" style="width: 100%;">
            <thead>
              <tr>
                <th>
                  <span>Report No</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('rptno')"></i>
                </th>
                <th>
                  <span>Report Date</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('rptdate')"></i>
                </th>
                <th>
                  <span>Report Type</span>
                </th>
                <th>
                  <span>Bill no</span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(report) in paginatedDatas" :key="report.rptno">
                <td> {{ report.rptno }}</td>
                <td>{{ report.rptdate }}</td>
                <td>{{ report.rpttype }}</td>
                <td>{{ report.billno }}</td>
                <td>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button class="btn btn-primary" data-bs-target="#myModal" data-bs-toggle="modal"
                      data-bs-whatever=report>Show</button>
                    <button class="btn btn-primary" type="button" @click="showModal">Button</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="myModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modal title</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Modal body text goes here.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
//import Papa from 'papaparse';
//import jsPDF from 'jspdf';
import * as cs from '../assets/js/commonscript.js'

export default {
  data() {
    return {
      reports: [],
      isLoading: false,
      error: null,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      showAddTooltip: false,
      sortDirection: {
        rptno: 'desc',
        rptdate: 'desc',
      },
    };
  },
  created() {
    this.fetchReports();
  },
  computed: {
    filteredDatas() {
      return this.reports.filter(report => {
        return Object.values(report).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedDatas() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredDatas.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredDatas.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage; // assuming you have a currentPage data property
      const maxPagesToShow = 10;
      let pages = [];
      if (totalPages <= maxPagesToShow) {
        // Show all pages if they are fewer than the maxPagesToShow
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        // Always show the first page
        pages.push(1);
        // Show a range around the current page
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);
        if (start > 2) {
          pages.push('...');
        }
        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
        if (end < totalPages - 1) {
          pages.push('...');
        }
        // Always show the last page
        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }
      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    }
  },
  watch: {
  },
  methods: {
    checkIsDate() {
            if (this.newContainer.fromdate != '') {
                if (cs.isvalidDate(this.newContainer.fromdate) == false) {
                    useToast().error('Invalid From Date');
                }
            }
        },
        checkIsToDate() {
            if (this.newContainer.todate != '') {
                if (cs.isvalidDate(this.newContainer.todate) == false) {
                    useToast().error('Invalid To Date');
                }
            }
        },
    showModal() {
      //this.$refs['myModal'].show()
      //this.$refs['myModal'].modal('show');
      //('#myModal').modal('show');
    },
    setRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    async fetchReports() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }
      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/all_reports', config);
        //console.log(response.data[2]);
        this.reports = response.data[2];
        localStorage.setItem('reports', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching report list:', error);
        this.error = 'Error fetching report list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style scoped>
.main {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.controls {
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-container input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.search-icon {
  margin-left: -30px;
  cursor: pointer;
}

.table-container {
  margin-bottom: 20px;
}

.vessel-table {
  width: 100%;
  border-collapse: collapse;
}

.vessel-table th, .vessel-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.vessel-table th {
  background-color: #f4f4f4;
}

.vessel-table tr:hover {
  background-color: #f1f1f1;
}

.filter-icon {
  cursor: pointer;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rows-per-table {
  display: flex;
}

.rows-per-table button {
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination button:disabled {
  background-color: #f4f4f4;
  cursor: not-allowed;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
}

.modal-header, .modal-footer {
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.modal-footer {
  border-top: 1px solid #ddd;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 16px;
}

.btn {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  opacity: .5;
}

.btn-close:hover {
  opacity: .75;
}
</style>
