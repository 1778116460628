<template>
  <div class="main">
    <div class="transaction-list">
      <h1 class="title">Damage Entry</h1>

      <div class="form-group">
        <div class="form-item">
          <label for="partyname">Party *</label>
          <v-select :clearable="false" tabindex="0" placeholder="Select Party Name" id="partyname"
            v-model="newContainer.partyName" :options="party" label="short_name" :reduce="party => party.autoid"
            selectOnTab="True" />
        </div>

        <div class="form-item">
          <label for="vesselName">Vessel *</label>
          <v-select :clearable="false" id="vesselName" placeholder="Select Vessel" v-model="newContainer.vesselID"
            ref="vesselName" :options="vessel" label="Name" :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
        </div>

        <div class="form-item">
          <label for="voyageName">Voyage *</label>
          <v-select :clearable="false" id="voyageName" v-model="newContainer.VoyId" :options="voyage" label="Name"
            :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage" selectOnTab="True" />
        </div>

        <div class="form-item">
          <label for="movementname">Movement Type *</label>
          <v-select :clearable="false" id="movementname" v-model="newContainer.movementID" :options="movementOptions"
            label="Name" :reduce="movement => movement.Movid" placeholder="Select Movement Name" selectOnTab="True" />
        </div>

        <div class="form-item">
          <label for="clearingAgent">Clearing Agent:</label>
          <v-select :clearable="false" id="clearingAgent" placeholder="Select Clearing Agent" :options="clearingAgents"
            v-model="newContainer.clearingAgent" selectOnTab="True" />
        </div>
      </div>

      <div v-if="showOverlay" class="overlay">
        <div class="overlay-content">
          <h2>Damage Details</h2>
          <form @submit.prevent="saveDamageDetails">
            <div class="overlay-form-group">
              <label for="location">Location:</label>
              <select id="location" v-model="damageDetails.location" ref="myInput" class="overlay-form-select">
                <option value=0 selected>Select Location</option>
                <option value=1>EXT</option>
                <option value=2>INT</option>
              </select>
              <label for="position">Position:</label>
              <v-select :clearable="false" style="width: 250px;" id="position" v-model="damageDetails.position"
                :options="positions" :reduce="pos => pos.id" placeholder="Select Position" label="name"
                class="overlay-form-select" selectOnTab="True" />

            </div>
            <div class="overlay-form-group">
              <label for="damageFrom">Damage From:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" placeholder="Select Damage From"
                v-model="damageDetails.damageFrom" :options="FromSec1" :reduce="fromsec => fromsec.id" label="name"
                class="overlay-form-select" append-to-body selectOnTab="True" />

              <label for="damageFrom">&</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" v-model="damageDetails.damageFromAnd"
                :options="FromSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                append-to-body selectOnTab="True" />

            </div>

            <div class="overlay-form-group">
              <label for="damageTo">Damage To:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageTo"
                :options="ToSec1" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                append-to-body placeholder="Select Damage To" selectOnTab="True" />

              <label for="damageTo">&:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageToAnd"
                :options="ToSec2" label="name" :reduce="fromsec => fromsec.id" class="overlay-form-select"
                append-to-body selectOnTab="True" />

            </div>

            <div class="overlay-form-group">
              <label style="width:100px" for="component">Component:</label>
              <v-select :clearable="false" style="width: 120px;" id="component" v-model="damageDetails.component"
                :options="components" label="description" :reduce="component => component.id" class="overlay-form-input"
                placeholder="Select Component" selectOnTab="True" />

              <label for="damageType">Damage Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="damageType" v-model="damageDetails.damageType"
                :options="damageTypes" label="description" :reduce="damageType => damageType.id"
                class="overlay-form-input" placeholder="Select Damage Type " selectOnTab="True" />

              <label for="repairType">Repair Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="repairType" v-model="damageDetails.repairType"
                :options="repairTypes" label="description" :reduce="repairType => repairType.id"
                class="overlay-form-input" placeholder="Select Repair Type" selectOnTab="True" />
            </div>
            <div class="overlay-form-group">
              <label for="length">Length:</label>
              <input style="width: 60px;" type="number" value="0" id="length" v-model="damageDetails.length"
                class="overlay-form-input" />
              <label for="breadth">Breadth:</label>
              <input style="width: 60px;" type="number" id="breadth" v-model="damageDetails.breadth"
                class="overlay-form-input" />
              <label for="area">Area:</label>
              <input style="width: 60px;" type="number" id="area" value="0" v-model="damageDetails.area"
                class="overlay-form-input" />
              <label for="quantity">Quantity:</label>
              <input style="width: 60px;" type="number" id="quantity" value="0" v-model="damageDetails.quantity"
                class="overlay-form-input" />
            </div>
            <div class="overlay-form-group">
              <label for="damenote">Note:</label>
              <textarea style="width: 80%;" type="text" value="0" id="damenote" v-model="damageDetails.note"
                class="overlay-form-input"></textarea>
            </div>

            <div class="overlay-form-group">
              <label for="dameimage">Image:</label>
              <input type="file" @change="onDameFileChange" ref="fileDamAdd" />
            </div>


            <button type="submit">Save</button>
            <button class="cancel-button" @click="closeOverlay">Cancel</button>
          </form>

          <!-- Table to display saved damage details -->
          <div v-if="damageDetailsList.length > 0">
            <table class="damage-details-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Position</th>
                  <th>Damage From</th>
                  <th>Damage To</th>
                  <th>Component</th>
                  <th>Damage Type</th>
                  <th>Repair Type</th>
                  <th>Length</th>
                  <th>Breadth</th>
                  <th>Area</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(detail, index) in damageDetailsList" :key="index">
                  <td>{{ detail.location }}</td>
                  <td>{{ detail.position }}</td>
                  <td>{{ detail.damageFrom }}</td>
                  <td>{{ detail.damageTo }}</td>
                  <td>{{ detail.component }}</td>
                  <td>{{ detail.damageType }}</td>
                  <td>{{ detail.repairType }}</td>
                  <td>{{ detail.length }}</td>
                  <td>{{ detail.breadth }}</td>
                  <td>{{ detail.area }}</td>
                  <td>{{ detail.quantity }}</td>
                </tr>
              </tbody>
            </table>
            <button class="done-button" @click="done">Done</button>
          </div>
        </div>
      </div>


    </div>
    <div v-if="isLoading">Loading transactions...</div>
    <div v-else-if="error">Error loading transactions: {{ error }}</div>

    <div v-else>
      <div class="table-container">
        <table class="movement-table table-design">
          <thead>
            <tr>
              <th class="field-100">Container</th>
              <th class="field-60">ISO</th>
              <th class="field-60">Tare Weight</th>
              <th class="field-60">Gross Weight</th>
              <th class="field-60">Year of MFG</th>
              <th class="field-60">Seal No</th>
              <th class="field-100">Inspection Date</th>
              <th class="field-60">Inspection Time</th>
              <th class="field-100">Place</th>
              <th class="field-60" v-if="shouldShowCFSID">CFS ID</th>
              <th class="field-60">Booking No</th>
              <th class="field-60" v-if="shouldShowPOL">POL</th>
              <th class="field-60" v-if="shouldShowPOD">POD</th>
              <th class="field-60" v-if="shouldShowFPD">FPD</th>
              <th class="field-60">HAZ STAT</th>
              <th class="field-60">Note</th>
              <th>Image</th>
              <th style="width: 80px" v-if="shouldShowSoundDamage">Sound/Damage</th>
            </tr>
          </thead>
          <tbody>
            <tr class="add-row">
              <td class="field-100">
                <input v-model="newContainer.contnumber" placeholder="Container Number" ref="containerInput"
                  @blur="getConatinerData" class="field-100" oninput="this.value = this.value.toUpperCase()" />
              </td>
              <td class="field-60">
                <v-select :clearable="false" v-model="newContainer.iso" :options="containeriso" label="isocode"
                  :reduce="iso => iso.isocode" placeholder="Select ISO" class="field-60" selectOnTab="True" />
              </td>

              <td class="field-60"><input class="field-60" v-model="newContainer.tareWeight"
                  placeholder="Tare Weight" /></td>
              <td class="field-60"><input class="field-60" type="number" v-model="newContainer.GrossWt"
                  placeholder="Gross Weight" /></td>
              <td class="field-60"><input class="field-60" v-model="newContainer.manufacture" placeholder="Year of MFG"
                  v-on:blur="create_update_container" /></td>
              <td class="field-60"><input class="field-60" v-model="newContainer.sealNo" placeholder="Seal No" /></td>
              <td class="field-100"><input class="field-100" type="text" v-model="newContainer.inspDate"
                  v-on:blur="checkIsDate()" /></td>
              <td class="field-60"><input class="field-60" type="text" v-model="newContainer.inspTime" /></td>

              <td class="field-100">
                <v-select :clearable="false" v-model="newContainer.locID" :options="location" label="Name"
                  :reduce="location => location.locationid" placeholder="Select Place" class="field-100" append-to-body
                  selectOnTab="True" />

              </td>
              <td v-if="shouldShowCFSID">
                <v-select :clearable="false" v-model="newContainer.cfsid" :options="cfs_location" label="Name"
                  :reduce="location => location.locationid" placeholder="Select CFS" class="field-100"
                  selectOnTab="True" append-to-body />
              </td>
              <td>
                <input v-model="newContainer.bookingNo" placeholder="Booking No" />
              </td>
              <td v-if="shouldShowPOL">
                <v-select :clearable="false" v-model="newContainer.pol" :options="ports" label="name"
                  :reduce="port => port.portid" placeholder="Select POL" selectOnTab="True" />
              </td>
              <td v-if="shouldShowPOD">
                <v-select :clearable="false" v-model="newContainer.pod" :options="ports" label="name"
                  :reduce="port => port.portid" placeholder="Select pod" selectOnTab="True" />
              </td>
              <td v-if="shouldShowFPD">
                <v-select :clearable="false" v-model="newContainer.fpd" :options="ports" label="name"
                  :reduce="port => port.portid" placeholder="Select fpd" selectOnTab="True" />
              </td>
              <td>
                <select v-model="newContainer.hazStat" class="custom-select">
                  <option value="N" selected>No</option>
                  <option value="Y" selected>Yes</option>
                </select>
                <!--<select v-model="newContainer.hazStat" :options="hazStatOptions" label="label"
                  :reduce="option => option.value" placeholder="Select Hazard Status" append-to-body />-->
              </td>
              <td>
                <textarea v-model="newContainer.note" placeholder="Note" rows="3"></textarea>
              </td>
              <td><input type="file" @change="onFileChange" ref="fileAdd" class="btn btn-default" multiple></td>
              <td v-if="shouldShowSoundDamage">
                <div class="sound-damage-container">
                  <label>
                    <input type="radio" v-model="newContainer.soundDamage" value="Sound"
                      @keypress.enter="handleCondition" />
                    Sound
                  </label>
                  <label>
                    <input type="radio" v-model="newContainer.soundDamage" value="Damage"
                      @keypress.enter="handleCondition" />
                    Damage
                  </label>
                </div>
              </td>
            </tr>

            <!-- Last 5 Successful Tasks -->
            <tr v-for="container in lastFiveSuccessfulTasks" :key="container.autoID" class="successful-task">
              <td class="container-field">{{ container.contnumber }}</td>
              <td>{{ container.short_name }}</td>
              <td>{{ container.vesselName }}</td>
              <td>{{ container.voyageName }}</td>
              <td>{{ container.movementName }}</td>
              <td>{{ container.locationName }}</td>
              <td>{{ container.sealNo }}</td>
              <td v-if="shouldShowISPTDate">{{ container.inspDate }}</td>
              <td v-if="shouldShowTime">{{ container.inspTime }}</td>
              <td v-if="shouldShowGrossWeight">{{ container.GrossWt }}</td>
              <td v-if="shouldShowCFSID">{{ container.cfsID }}</td>
              <td v-if="shouldShowPOL">{{ container.pol }}</td>
              <td v-if="shouldShowPOD">{{ container.pod }}</td>
              <td v-if="shouldShowFPD">{{ container.fpd }}</td>
              <td>{{ container.hazStat }}</td>
              <td>{{ container.note }}</td>
              <td v-if="shouldShowSoundDamage">{{ container.damage }}</td>
            </tr>
            <!-- Container List -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>




<script>
import axios from 'axios';
//import { part } from 'core-js/core/function';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import * as cs from '../assets/js/commonscript.js'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      transactions: [],
      party: JSON.parse(localStorage.getItem('party')),
      accounts: JSON.parse(localStorage.getItem('accounts')) || [],
      applicants: JSON.parse(localStorage.getItem('applicants')) || [],
      vessel: JSON.parse(localStorage.getItem('vessel')) || [],
      voyage: JSON.parse(localStorage.getItem('voyage')) || [],
      movementOptions: JSON.parse(localStorage.getItem('movements')) || [],
      ports: JSON.parse(localStorage.getItem('ports')) || [],
      location: JSON.parse(localStorage.getItem('locations')) || [],
      cfs_location: JSON.parse(localStorage.getItem('cfs_location')) || [],
      locations: [
        { id: 1, name: 'EXT' },
        { id: 2, name: 'INT' },],
      positions: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Left' },
        { id: 2, name: 'Front' },
        { id: 3, name: 'Right' },
        { id: 4, name: 'Top' },
        { id: 5, name: 'Rear' },
        { id: 6, name: 'Interior' },
        { id: 7, name: 'Under Structure' },
        { id: 8, name: 'Exterior' },
      ],
      FromSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      FromSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],

      ToSec1: [
        { id: 0, name: 'Select One' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
      ],
      ToSec2: [
        { id: 0, name: 'Select One' },
        { id: 1, name: 'Top' },
        { id: 2, name: 'Bottom' },
        { id: 3, name: 'Left' },
        { id: 4, name: 'Right' },
      ],
      clearingAgents: ['Agent A', 'Agent B', 'Agent C'], // Static options
      reportTypes: ['Survey', 'Comparator', 'Non-EOR'],
      movement: [],
      nameError: '',
      isLoading: false,
      error: null,
      clearingAgent: null,
      damageTypes: [],
      repairTypes: [],
      components: [],
      reportType: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      selectedTransaction: null,
      imagefiles: [],
      newContainer: {
        partyName: 0,
        applicantID: 0,
        applicantname: '',
        accountID: 0,
        accountname: '',
        vesselID: 0,
        VesselName: '',
        VoyId: 0,
        VoyageName: '',
        containerID: 0,
        contnumber: '',
        movementID: 0,
        MovementName: '',
        locID: 0,
        LocationName: '',
        blNo: null,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: this.getCurrentDate(),
        inspTime: '',
        damage: '',
        hazStat: 'N'
      },
      containeriso: [],
      containerSizes: [
        { value: 20, text: '20' },
        { value: 40, text: '40' },
      ],
      successfulTasks: [],
      hazStatOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ],
      newTransaction: {
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: '',
        isogrosswt: '',
        applicantname: '',
        accountname: '',
        VesselName: '',
        Colsign: '',
        VoyageName: '',
        MovementName: '',
        LocationName: '',
      },
      sortDirection: {
        contnumber: 'asc',
        contsize: 'asc',
        conttype: 'asc',
        TypeText: 'asc',
        construction: 'asc',
        iso_code: 'asc',
        isotarewt: 'asc',
        isogrosswt: 'asc',
        applicantname: 'asc',
        accountname: 'asc',
        VesselName: 'asc',
        Colsign: 'asc',
        VoyageName: 'asc',
        MovementName: 'asc',
        LocationName: 'asc',
      },
      damageDetails: {
        location: 0,
        position: 0,
        damageFrom: 0,
        damageFromAnd: 0,
        damageTo: 0,
        damageToAnd: 0,
        component: 0,
        damageType: 0,
        repairType: 0,
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0,
        note: '',
        Image: null
      },
      damageDetailsList: [],
      damageDbList: [],
      showOverlay: false,
      maxDate: new Date().toISOString().split('T')[0], // Current date for date inputs
    };
  },
  created() {
    this.checkPermissions();
    this.fetchcontaineriso();
    this.fetchData();
    
    /*document.addEventListener('keydown', (e) => {
      console.log(e.key)
      //if(e.key=='Enter'){
      //this.$refs['vesselName'].focus()
      //  const focuscontnumberfld = this.$refs['vesselName']; // Assuming it's a ref array, hence [0]
      //  console.log(focuscontnumberfld)
      //  focuscontnumberfld[0].searchEl.focus(); 
      //const VesselName = this.$refs.vesselName;
      //console.log(VesselName)
      //VesselName[0].focus()
      //e.sendkey='Tab'
      //}
    });
        let keysPressed = {};
        document.addEventListener('keydown', (e) => {
          keysPressed[e.key] = true;
          if (keysPressed['Alt'] && e.key == 's') {
            this.newContainer.soundDamage = 'Sound'
            this.ValidateTransaction('Sound')
          }
          else if (keysPressed['Alt'] && e.key == 'd') {
            this.newContainer.soundDamage = 'Damage'
            this.ValidateTransaction('Sound');
          }
          document.addEventListener('keyup', (e) => {
            delete keysPressed[e.key];
          });
        });*/
  },
  computed: {
    resetTransaction() {
      return {
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: '',
        isogrosswt: '',
        applicantname: '',
        accountname: '',
        VesselName: '',
        Colsign: '',
        VoyageName: '',
        MovementName: '',
        LocationName: '',
      };
    },
    filteredTransactions() {
      return this.transactions.filter(transaction => {
        return Object.values(transaction).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    shouldShowISPTDate() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowTime() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowGrossWeight() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowCFSID() {
      return ['23', '24', '25', '42', '43'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOL() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowFPD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowSoundDamage() {
      return ['3', '10', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredTransactions.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredTransactions.length / this.rowsPerPage);
    },
  },
  methods: {
    checkIsDate() {
      if (this.newContainer.inspDate != '') {
        if (cs.isvalidDate(this.newContainer.inspDate) == false) {
          //this.formData.fromdate
          useToast().error('Invalid Date');
        }
      }
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },
    async getConatinerData() {
      try {
        const contnum = this.newContainer.contnumber
        if (contnum == "")
          return;
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.get(`/container/${contnum}`, config);
        this.containerdata = response.data[2];
        //console.log(this.containerdata)
        if (this.containerdata.length > 0) {
          this.newContainer.containerID = this.containerdata[0]['contid']
          this.newContainer.iso = this.containerdata[0]['iso_code']
          this.newContainer.tareWeight = this.containerdata[0]['isotarewt']
          this.newContainer.GrossWt = this.containerdata[0]['isogrosswt']
          this.newContainer.manufacture = this.containerdata[0]['manufacture']
        } else {
          //console.log("Container Details not found")
          useToast().error("Container Details not found")
          this.newContainer.containerID = ''
        }
      }
      catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }
    },
    async create_update_container() {
      const contnum = this.newContainer.contnumber;
      const contiso = this.newContainer.iso;
      const isotarewt = this.newContainer.tareWeight || 0;
      const isogrosswt = this.newContainer.GrossWt || 0;
      const manufacture = this.newContainer.manufacture || null;
      //console.log(contnum);
      //console.log(contiso);
      const selectediso = this.containeriso.find(iso => iso.isocode === contiso);
      //console.log(selectediso);

      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
      };
      if (this.newContainer.containerID == 0 || this.newContainer.containerID == '') {
        const contData = {
          contnumber: contnum,
          contsize: selectediso.size,
          conttype: selectediso.typecode,
          TypeText: selectediso.typetext,
          construction: 'Steel',
          iso_code: selectediso.isocode,
          isotarewt: isotarewt,
          isogrosswt: isogrosswt,
          manufacture: manufacture,
          is_active: true
        }
        console.log(contData);
        const data = new FormData();
        const x = JSON.stringify(contData)
        console.log(x)
        data.append('container', x)
        const response = await axios.post('/create_container', data, config);
        console.log(response);
        this.containerdata = response.data[2];
        //console.log(this.containerdata)
        this.newContainer.containerID = this.containerdata['contid']
        this.newContainer.iso = this.containerdata['iso_code']
        this.newContainer.tareWeight = this.containerdata['isotarewt']
        this.newContainer.GrossWt = this.containerdata['isogrosswt']
        this.newContainer.manufacture = this.containerdata['manufacture']
      } else {
        const contData = {
          contnumber: contnum,
          contsize: selectediso.size,
          conttype: selectediso.typecode,
          TypeText: selectediso.typetext,
          construction: 'Steel',
          iso_code: selectediso.isocode,
          isotarewt: isotarewt,
          isogrosswt: isogrosswt,
          manufacture: manufacture,
          is_active: true,
          contid: this.newContainer.containerID
        }
        console.log(contData);
        const data = new FormData();
        const x = JSON.stringify(contData)
        console.log(x)
        data.append('container', x)
        const response = await axios.put(`/update_container`, data, config);
        //console.log(response);
        this.containerdata = response.data[2];
        //console.log(this.containerdata)
      }
    },
    async fetchcontaineriso() {
      const token = localStorage.getItem('access_token');

      try {
        const response = await axios.get('/container_iso', {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        // Assuming the response structure is [1, "Data Found", [{...}, {...}]]
        this.containeriso = response.data[2]; // Extracting the container types from the response
      } catch (error) {
        console.error('Error fetching container types:', error);
      }
    },
    onFileChange(e) {
      var selectedFiles = e.target.files;
      for (var i = 0; i < selectedFiles.length; i++) {
        this.imagefiles.push(selectedFiles[i]);
      }
    },
    onDameFileChange(e) {
      var selectedFiles = e.target.files;
      for (var i = 0; i < selectedFiles.length; i++) {
        this.damageDetails.Image = selectedFiles[i];
      }
    },
    getNameById(array, id, key = 'id', nameKey = 'name') {
      const item = array.find(item => item[key] === id);
      return item ? item[nameKey] : '';
    },
    handleCondition(event) {
      // Check if the Enter key was pressed
      if (event.key === 'Enter') {
        //console.log(this.newContainer)

        if (this.newContainer.soundDamage === 'Sound') {
          this.ValidateTransaction('Sound')
        } else if (this.newContainer.soundDamage === 'Damage') {
          this.ValidateTransaction('Damage')
        }
      }
    },
    ValidateTransaction(cond) {
      const party = this.party.find(party => party.autoid === this.newContainer.partyName);
      if (party) {
        this.newContainer.applicantID = party.applicantId
        this.newContainer.applicantname = party.applicantname
        this.newContainer.accountID = party.accountId
        this.newContainer.accountname = party.accountname
      }
      //console.log(party)
      if (!this.newContainer.containerID || !this.newContainer.vesselID || !this.newContainer.VoyId || !this.newContainer.movementID || !this.newContainer.locID || !this.newContainer.inspDate || !this.newContainer.inspTime || !this.newContainer.hazStat) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields before selecting Sound or Damage.');
        return;
      }
      if (cond === 'Sound') {
        this.SaveTransactionDate('Sound')
      } else if (cond === 'Damage') {
        this.openDamageOverlay();
      }
    },
    async SaveTransactionDate(conditionType) {
      //console.log(this.newContainer)

      const conditionId = conditionType === 'Sound' ? 2 : 1;
      const transactionData = {
        containerId: this.newContainer.containerID || 0,
        containerNumber: this.newContainer.contnumber || '',
        applicant: this.newContainer.applicantID || 0,
        account: this.newContainer.accountID || 0,
        vesselld: this.newContainer.vesselID || 0,
        voyage: this.newContainer.VoyId || 0,
        movementId: this.newContainer.movementID || 0,
        locationId: this.newContainer.locID || 0,
        cfsId: this.newContainer.cfsID || 0,
        cfsName: this.newContainer.cfsName, // Use saved name
        seal: this.newContainer.sealNo || '',
        inspdate: cs.ConvertToISODate(this.newContainer.inspDate || this.getCurrentDate()),
        insptime: this.newContainer.inspTime || '',
        conditionid: conditionId,
        inspectionplace: this.newContainer.locID,
        condition: conditionType,
        note: this.newContainer.note || '',
        cargowt: this.newContainer.GrossWt || 0,
        hazstat: this.newContainer.hazStat || 'N',
        loadport: this.newContainer.pol || 0,
        loadPortName: this.newContainer.loadPortName, // Use saved name
        destnport: this.newContainer.pod || 0,
        destnPortName: this.newContainer.destnPortName, // Use saved name
        transport: this.newContainer.fpd || 0,
        transportName: this.newContainer.transportName, // Use saved name
      };
      //console.log(transactionData)
      const vesselName = this.getNameById(this.vessel, this.newContainer.vesselID, 'Vesselid', 'Name');
      const voyageName = this.getNameById(this.voyage, this.newContainer.VoyId, 'Voyageid', 'Name');
      const locationName = this.getNameById(this.location, this.newContainer.locID, 'locationid', 'Name');
      const movementName = this.getNameById(this.movementOptions, this.newContainer.movementID, 'Movid', 'Name');
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'multipart/form-data'
          },
        };
        const data = new FormData();
        const x = JSON.stringify(transactionData)
        data.append('transaction', x)
        //var fileInput = document.getElementById('fileAdd');
        const fileInput = this.$refs.fileAdd;
        //if (!fileInput) {
        for (const file of fileInput.files) {
          data.append("files", file);
        }
        //}else{
        //  data.append("files", null);
        //}
        if (conditionId == 1) {
          const damagedt = JSON.stringify(this.damageDbList)
          //console.log(damagedt);
          data.append('damageData', damagedt)
        }
        //else{
        //  data.append('damageData', null)
        //}

        const response = await axios.post('/create_transaction', data, config);
        if (response.data[0] === 0) {
          // Display success message using the second element of the response
          useToast().error(response.data[1]);
        } else {
          useToast().success('Created Succesful');
          this.successfulTasks.push({
            ...this.newContainer, conditionType,
            sealNo: this.newContainer.sealNo,
            note: this.newContainer.note,
            vesselName,
            voyageName,
            locationName,
            movementName,
            short_name: this.party
          });
          //console.log(this.successfulTasks, 'hello')
          this.resetNewContainer();
        }

      } catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }

    },
    resetNewContainer() {
      this.newContainer = {
        applicantID: null,
        applicantname: '',
        accountID: null,
        accountname: '',
        vesselID: null,
        VesselName: '',
        VoyId: null,
        VoyageName: '',
        containerID: null,
        contnumber: '',
        movementID: null,
        MovementName: '',
        locID: null,
        LocationName: '',
        blNo: null,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: this.getCurrentDate(),
        inspTime: '',
        damage: '',
        hazStat: 'N'
      },
        this.damageDetails = {
          location: 0,
          position: 0,
          damageFrom: 0,
          damageFromAnd: 0,
          damageTo: 0,
          damageToAnd: 0,
          component: 0,
          damageType: 0,
          repairType: 0,
          length: 0,
          breadth: 0,
          area: 0,
          quantity: 0
        },
        this.damageDetailsList = [],
        this.damageDbList = [],
        this.$refs.fileAdd = null
    },
    fetchData() {
      const accessToken = localStorage.getItem('access_token');

      axios.get('/active_damage_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const damageTypesResponse = response.data[2];
          this.damageTypes = damageTypesResponse.map(damageType => ({
            id: damageType.autoid,
            name: damageType.shortName,
            description: damageType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('/active_repair_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const repairTypesResponse = response.data[2];
          this.repairTypes = repairTypesResponse.map(repairType => ({
            id: repairType.autoid,
            name: repairType.shortName,
            description: repairType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('/active_component_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const componentsResponse = response.data[2];
          this.components = componentsResponse.map(component => ({
            id: component.autoid,
            name: component.shortName,
            description: component.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });
    },
    checkPermissions() {
      const user = JSON.parse(localStorage.getItem('user'));
      this.hasPermissions = user && user.role === 'admin';
    },
    editTransaction(transaction) {
      this.selectedTransaction = transaction;
      this.newTransaction = { ...transaction };
    },
    async saveTransaction() {
      if (this.nameError) return;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }

      try {
        const response = await axios.post('/add_transaction', this.newTransaction, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const index = this.transactions.findIndex(t => t.reportid === response.data[2].reportid);
        if (index !== -1) {
          this.transactions.splice(index, 1, response.data[2]);
        } else {
          this.transactions.push(response.data[2]);
        }
        this.selectedTransaction = null;
        this.newTransaction = { ...this.resetTransaction };
      } catch (err) {
        this.error = 'Error saving transaction';
        console.error(err);
      }
    },
    cancelEdit() {
      this.selectedTransaction = null;
      this.newTransaction = { ...this.resetTransaction };
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    openDamageOverlay() {
      this.showOverlay = true;
      //this.$refs.myInput.focus();
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    saveDamageDetails() {

      const dmglist = {
        location: this.getNameById(this.locations, this.damageDetails.location, 'id', 'name') || '',
        position: this.getNameById(this.positions, this.damageDetails.position, 'id', 'name') || '',
        damageFrom: this.getNameById(this.FromSec1, this.damageDetails.damageFrom, 'id', 'name') || '',
        damageFromAnd: this.getNameById(this.FromSec2, this.damageDetails.damageFromAnd, 'id', 'name') || '',
        damageTo: this.getNameById(this.ToSec1, this.damageDetails.damageTo, 'id', 'name') || '',
        damageToAnd: this.getNameById(this.ToSec2, this.damageDetails.damageToAnd, 'id', 'name') || '',
        component: this.getNameById(this.components, this.damageDetails.component, 'id', 'description') || '',
        damageType: this.getNameById(this.damageTypes, this.damageDetails.damageType, 'id', 'description') || '',
        repairType: this.getNameById(this.repairTypes, this.damageDetails.repairType, 'id', 'description') || '',
        length: this.damageDetails.length || '',
        breadth: this.damageDetails.breadth || '',
        area: this.damageDetails.area || '',
        quantity: this.damageDetails.quantity || '',
      }
      this.damageDetailsList.push(dmglist);
      useToast().success('Damage Added');
      const dmg = {
        damlocationid: this.damageDetails.location || 0,
        positionid: this.damageDetails.position || 0,
        damageFrom: this.damageDetails.damageFrom || 0,
        damageFromAnd: this.damageDetails.damageFromAnd || 0,
        damageTo: this.damageDetails.damageTo || 0,
        damageToAnd: this.damageDetails.damageToAnd || 0,
        component: this.damageDetails.component || 0,
        damageType: this.damageDetails.damageType || 0,
        repairType: this.damageDetails.repairType || 0,
        length: this.damageDetails.length || 0,
        breadth: this.damageDetails.breadth || 0,
        area: this.damageDetails.area || 0,
        quantity: this.damageDetails.quantity || 0,
        note: this.damageDetails.note || '',
        Image: this.$refs.fileDamAdd.files[0]
      };
      console.log(this.damageDetails);
      this.damageDbList.push(dmg);
      // Clear the form fields after saving
      this.damageDetails = {
        location: 0,
        position: 0,
        damageFrom: 0,
        damageTo: 0,
        component: 0,
        damageType: 0,
        repairType: 0,
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0,
        note: '',
        Image: null
      };
    },
    async done() {
      //console.log(this.damageDbList)
      this.SaveTransactionDate('Damage')
      this.closeOverlay();
    },
  },
};
</script>


<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Main container styling */
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

/* Header styling */
.title {
  font-size: 24px;
  margin-bottom: 20px;
}


/* Form group styling for form elements */
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 15px;
}

.form-item {
  width: 30%;
}



select,
input[type="text"],
input[type="date"],
input[type="number"],
v-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #FFF;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}

.excel-button {
  background-color: #17a2b8;
  color: white;
}

.generate-button {
  background-color: #28a745;
  color: white;
}

.cancel-button1 {
  background-color: #dc3545;
  color: white;
}

button:hover {
  opacity: 0.8;
}

/* Tooltip styling */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  /* Add background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* Allow scrolling if content overflows */
  z-index: 1000;
  /* Ensure overlay is on top of other content */
}

/* Overlay content styling */
.overlay-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  /* Allow more width */
  max-height: 90%;
  /* Restrict height to avoid overflow */
  overflow-y: auto;
  /* Scroll if content overflows vertically */
  z-index: 1001;
  /* Ensure content is above overlay background */
}

/* Overlay form group styling */
/* Overlay form group styling */
.overlay-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* Add space between form groups */
  gap: 5px;
  /* Add space between fields */
}

/* Styling for labels to ensure alignment */
.overlay-form-group label {
  margin-right: 2px;
  width: 70px;
  /* Set a fixed width for labels */
}

/* Styling for input and select elements to take available space */

.overlay-form-group .overlay-form-select {
  width: 350px;
  /* adjust the width to your desired size */
  font-size: 12px;
  /* adjust the font size to your desired size */
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #f68677;
}


/* Styling for the movement table */
.movement-table {
  background-color: #fff;
}

/* Table header styling */
.movement-table th {
  background-color: #f0f0f0;
  border-bottom: 2px solid #ccc;
  /* Set a minimum width for each column */
}

/* Table body styling */
.movement-table td {
  padding: 5px 2px;
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
  /* Add border between rows */
}

/* Button row at the bottom */

/* Button styling */
button {
  padding: 10px 15px;
  border: none;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.sound-damage-container {
  display: flex;
  /* Use flexbox to align the radio buttons */
  flex-direction: row;
  /* Align the radio buttons horizontally */
  align-items: center;
  /* Vertically center the radio buttons */
}

.sound-damage-container label {
  margin-right: 10px;
  /* Add some space between the radio buttons */
}

.sound-damage-container input[type="radio"] {
  margin-right: 5px;
  /* Add some space between the radio button and its label */
}

button:hover {
  background-color: #218838;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {

  .movement-table th,
  .movement-table td {
    font-size: 12px;
    /* Reduce font size on smaller screens */
  }

  .table-container {
    height: auto;
    /* Allow the table to grow in height */
  }
}


/* Table styling */
.damage-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.damage-details-table th {
  padding: 10px 15px;
  background-color: #f0f0f0;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  text-align: left;
  border-bottom: 2px solid #ccc;
}

.damage-details-table td {
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
}

/* Button styling */
.done-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
}

.done-button:hover {
  background-color: #218838;
}
</style>
